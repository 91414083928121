import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import List from './List';
import Form from './Form';

interface IProps {
  fetchCustomerProposalRequests: () => void;
  history: any;
  location: any;
  loading: boolean;
}
interface IState {}

class ProposalRequests extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { fetchCustomerProposalRequests } = this.props;

    fetchCustomerProposalRequests();
  }

  public render() {
    const { history, location } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route path="/dashboard/customers/proposal-requests/list" exact component={List} />
          <Route path="/dashboard/customers/proposal-requests/new" exact render={() => <Form history={history} location={location} />} />
          <Route path="/dashboard/customers/proposal-requests/proposalrequestid/:proposalrequestid/edit" exact render={(props) => <Form history={history} location={location} proposalrequestid={props.match.params.proposalrequestid} />} />
          <Redirect to="/authenticate" />
        </Switch>
      </Router>
    );
  }
};

export default ProposalRequests;