import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchCustomerProposalRequests } from '../../../../actions/customers';
import { IAppState } from '../../../../reducers/index';
import ProposalRequests from './ProposalRequests';

const mapStateToProps = ({ customers }: IAppState) => ({
  loading: customers.proposals.loading,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchCustomerProposalRequests: () =>
      dispatch(fetchCustomerProposalRequests.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalRequests);
