import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as types from '../actions/customers';
import {
  createCustomer,
  createCustomerProposal,
  createCustomerProposalRequest,
  editCustomer,
  toggleCustomer,
  editCustomerProposal,
  editCustomerProposalRequest,
  fetchCustomers,
  createCustomerRep,
  editCustomerRep,
  deleteCustomerRep,
  fetchCustomerProposals,
  fetchCustomerProposalRequests,
} from '../api/customers';
import Customer, { CustomerWithMeta } from '../models/Customer';
import Proposal from '../models/Proposal';
import { CustomerRep } from '../models/Rep';
import ProposalRequest, { ProposalRequestWithMeta } from '../models/ProposalRequest';

export function* fetchCustomersSaga() {
  try {
    const customers: CustomerWithMeta[] = yield call(fetchCustomers);

    yield put(types.fetchCustomers.success({ customers }));
  } catch (error: any) {
    yield put(types.fetchCustomers.failure({ error }));
  }
}

export function* createCustomerSaga(action: ActionType<typeof types.createCustomer.request>) {
  try {
    const customer: Customer = yield call(createCustomer, action.payload);

    yield put(types.createCustomer.success({ customer }));
  } catch (error: any) {
    yield put(types.createCustomer.failure({ error }));
  }
}

export function* editCustomerSaga(action: ActionType<typeof types.editCustomer.request>) {
  try {
    const customer: Customer = yield call(editCustomer, action.payload);

    yield put(types.editCustomer.success({ customer }));
  } catch (error: any) {
    yield put(types.editCustomer.failure({ error }));
  }
}

export function* toggleCustomerSaga(action: ActionType<typeof types.toggleCustomer.request>) {
  try {
    yield call(toggleCustomer, action.payload);

    yield put(types.toggleCustomer.success(action.payload));
  } catch (error: any) {
    yield put(types.toggleCustomer.failure({ error }));
  }
}

export function* createCustomerRepSaga(action: ActionType<typeof types.createCustomerRep.request>) {
  try {
    const rep: CustomerRep = yield call(createCustomerRep, action.payload);

    yield put(types.createCustomerRep.success({ rep }));
  } catch (error: any) {
    yield put(types.createCustomerRep.failure({ error }));
  }
}

export function* editCustomerRepSaga(action: ActionType<typeof types.editCustomerRep.request>) {
  try {
    const rep: CustomerRep = yield call(editCustomerRep, action.payload);

    yield put(types.editCustomerRep.success({ rep }));
  } catch (error: any) {
    yield put(types.editCustomerRep.failure({ error }));
  }
}

export function* deleteCustomerRepSaga(action: ActionType<typeof types.deleteCustomerRep.request>) {
  try {
    yield call(deleteCustomerRep, action.payload);

    yield put(types.deleteCustomerRep.success(action.payload));
  } catch (error: any) {
    yield put(types.deleteCustomerRep.failure({ error }));
  }
}

export function* fetchCustomerProposalsSaga() {
  try {
    const proposals: Proposal[] = yield call(fetchCustomerProposals);

    yield put(types.fetchCustomerProposals.success({ proposals }));
  } catch (error: any) {
    yield put(types.fetchCustomerProposals.failure({ error }));
  }
}

export function* createCustomerProposalSaga(action: ActionType<typeof types.createCustomerProposal.request>) {
  try {
    const proposal: Proposal = yield call(createCustomerProposal, action.payload);

    yield put(types.createCustomerProposal.success({ proposal }));
  } catch (error: any) {
    yield put(types.createCustomerProposal.failure({ error }));
  }
}

export function* editCustomerProposalSaga(action: ActionType<typeof types.editCustomerProposal.request>) {
  try {
    const proposal: Proposal = yield call(editCustomerProposal, action.payload);

    yield put(types.editCustomerProposal.success({ proposal }));
  } catch (error: any) {
    yield put(types.editCustomerProposal.failure({ error }));
  }
}

export function* fetchCustomerProposalRequestsSaga() {
  try {
    const proposalRequests: ProposalRequestWithMeta[] = yield call(fetchCustomerProposalRequests);

    yield put(types.fetchCustomerProposalRequests.success({ proposalRequests }));
  } catch (error: any) {
    yield put(types.fetchCustomerProposalRequests.failure({ error }));
  }
}

export function* createCustomerProposalRequestSaga(action: ActionType<typeof types.createCustomerProposalRequest.request>) {
  try {
    const proposalRequest: ProposalRequest = yield call(createCustomerProposalRequest, action.payload);

    yield put(types.createCustomerProposalRequest.success({ proposalRequest }));
  } catch (error: any) {
    yield put(types.createCustomerProposalRequest.failure({ error }));
  }
}

export function* editCustomerProposalRequestSaga(action: ActionType<typeof types.editCustomerProposalRequest.request>) {
  try {
    const proposalRequest: ProposalRequest = yield call(editCustomerProposalRequest, action.payload);

    yield put(types.editCustomerProposalRequest.success({ proposalRequest }));
  } catch (error: any) {
    yield put(types.editCustomerProposalRequest.failure({ error }));
  }
}
