import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import Machine from '../../../models/Machine';
import { MachineTypes } from '../../Dashboard/Machines/sources';

interface IProps {
  machine: Machine;
}

const InjectionUnit: FunctionComponent <IProps> = (properties) => {
  const {
    type,

    injectionUnitFuseDiameter,
    injectionUnitMaxRPMFuse,
    injectionUnitVolume,
    injectionUnitMaxWeight,
    injectionUnitPression,
    injectionUnitRatio,
    injectionCenterRingDiameter,
    injectionScrewStrock,
    injectionRate,
    injectionNozzleContactForce,
    injectionNozzleStroke,
    injectionResineSystem,
    ndInjectionUnit,
    ndInjectionUnitFuseDiameter,
    ndInjectionUnitMaxRPMFuse,
    ndInjectionUnitVolume,
    ndInjectionUnitMaxWeight,
    ndInjectionUnitPression,
    ndInjectionUnitRatio,
    ndInjectionCenterRingDiameter,
    ndInjectionScrewStrock,
    ndInjectionRate,
    ndInjectionNozzleContactForce,
    ndInjectionNozzleStroke,
    ndInjectionResineSystem,
    pressDimensionLength,
    pressDimensionWidth,
    pressDimensionHeight,
    pressWeight,
  } = properties.machine;

  return (
    <>
      {(
        ndInjectionUnit !== null ||
        ((type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.PLASTIC_INJECTION_VERTICAL) && (
          injectionUnitFuseDiameter ||
          injectionUnitMaxRPMFuse ||
          injectionUnitVolume ||
          injectionUnitMaxWeight ||
          injectionUnitPression ||
          injectionUnitRatio ||
          injectionCenterRingDiameter ||
          injectionScrewStrock ||
          injectionRate ||
          injectionNozzleContactForce ||
          injectionNozzleStroke
        )) || (type === MachineTypes.TRY_OUT_PRESS && injectionResineSystem !== null)
      ) && (
        <>
          <Box pt={2}>
            <Card>
              <CardContent>
                <Box mb={2}>
                  <Typography className="subtitle" component="b">Unidade de Injecção</Typography>
                </Box>

                <Grid container spacing={2}>
                  {(type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.PLASTIC_INJECTION_VERTICAL) && (
                    <>
                      {injectionUnitFuseDiameter ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Diâmetro do fuso</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionUnitFuseDiameter} mm</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionUnitMaxRPMFuse ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>RPM máximo do fuso</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionUnitMaxRPMFuse}</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionUnitVolume ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Volume de Injecção</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionUnitVolume} cm3</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionUnitMaxWeight ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Peso máximo de injecção (em PS)</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionUnitMaxWeight} g</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionUnitPression ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Pressão de injecção</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionUnitPression} bar</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionUnitRatio ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Racio de injecção (L/D)</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionUnitRatio}</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionCenterRingDiameter ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Center Ring Diameter</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionCenterRingDiameter} mm</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionScrewStrock ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Screw Stroke - Curso do fuso</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionScrewStrock} mm</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionRate ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Injection Rate - Velocidade de Injecção</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionRate} cm3/s</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionNozzleContactForce ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Nozzle contact force - Força contato do bico Injecção</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionNozzleContactForce} t</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                      {injectionNozzleStroke ? (
                        <>
                          <Grid item sm={6}>
                            <Typography variant="body2">
                              <b>Nozzle stroke - Curso carro Injecção</b>
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant="body2">{injectionNozzleStroke} mm</Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Divider />
                          </Grid>
                        </>
                      ) : null}
                    </>
                  )}
                  {type === MachineTypes.TRY_OUT_PRESS && injectionResineSystem !== null ? (
                    <>
                      <Grid item sm={6}>
                        <Typography variant="body2">
                          <b>Tem sistema de injecção de resina</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography variant="body2">{injectionResineSystem ? 'Sim' : 'Não'}</Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>
                    </>
                  ) : null}
                  {ndInjectionUnit !== null ? (
                    <>
                      <Grid item sm={6}>
                        <Typography variant="body2">
                          <b>Tem 2ª unidade de injeção</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <Typography variant="body2">{ndInjectionUnit ? 'Sim' : 'Não'}</Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <Divider />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </CardContent>
            </Card>
          </Box>
          {ndInjectionUnit !== null && (
            ((type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.PLASTIC_INJECTION_VERTICAL) && (
              ndInjectionUnitFuseDiameter ||
              ndInjectionUnitMaxRPMFuse ||
              ndInjectionUnitVolume ||
              ndInjectionUnitMaxWeight ||
              ndInjectionUnitPression ||
              ndInjectionUnitRatio ||
              ndInjectionCenterRingDiameter ||
              ndInjectionScrewStrock ||
              ndInjectionRate ||
              ndInjectionNozzleContactForce ||
              ndInjectionNozzleStroke
            )) || (type === MachineTypes.TRY_OUT_PRESS && ndInjectionResineSystem !== null)
          ) && (
            <Box pt={2}>
              <Card>
                <CardContent>
                  <Box mb={2}>
                    <Typography className="subtitle" component="b">Unidade de Injecção (2)</Typography>
                  </Box>

                  <Grid container spacing={2}>
                    {(type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.PLASTIC_INJECTION_VERTICAL) && (
                      <>
                        {ndInjectionUnitFuseDiameter ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Diâmetro do fuso</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionUnitFuseDiameter} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionUnitMaxRPMFuse ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>RPM máximo do fuso</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionUnitMaxRPMFuse}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionUnitVolume ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Volume de Injecção</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionUnitVolume} cm3</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionUnitMaxWeight ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Peso máximo de injecção (em PS)</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionUnitMaxWeight} g</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionUnitPression ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Pressão de injecção</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionUnitPression} bar</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionUnitRatio ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Racio de injecção (L/D)</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionUnitRatio}</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionCenterRingDiameter ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Center Ring Diameter</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionCenterRingDiameter} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionScrewStrock ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Screw Stroke - Curso do fuso</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionScrewStrock} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionRate ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Injection Rate - Velocidade de Injecção</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionRate} cm3/s</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionNozzleContactForce ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Nozzle contact force - Força contato do bico Injecção</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionNozzleContactForce} t</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                        {ndInjectionNozzleStroke ? (
                          <>
                            <Grid item sm={6}>
                              <Typography variant="body2">
                                <b>Nozzle stroke - Curso carro Injecção</b>
                              </Typography>
                            </Grid>
                            <Grid item sm={6}>
                              <Typography variant="body2">{ndInjectionNozzleStroke} mm</Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <Divider />
                            </Grid>
                          </>
                        ) : null}
                      </>
                    )}
                    {type === MachineTypes.TRY_OUT_PRESS && ndInjectionResineSystem !== null ? (
                      <>
                        <Grid item sm={6}>
                          <Typography variant="body2">
                            <b>Tem sistema de injecção de resina</b>
                          </Typography>
                        </Grid>
                        <Grid item sm={6}>
                          <Typography variant="body2">{ndInjectionResineSystem ? 'Sim' : 'Não'}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          )}
        </>
      )}
      {type === MachineTypes.TRY_OUT_PRESS && (
        pressDimensionLength ||
        pressDimensionWidth ||
        pressDimensionHeight ||
        pressWeight
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Prensa</Typography>
              </Box>
              <Grid container spacing={2}>
                {pressDimensionLength ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensão da prensa (C)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{pressDimensionLength} m</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {pressDimensionWidth ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensão da prensa (L)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{pressDimensionWidth} m</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {pressDimensionHeight ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Dimensão da prensa (A)</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{pressDimensionHeight} m</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {pressWeight ? (
                  <>
                    <Grid item sm={6}>
                      <Typography variant="body2">
                        <b>Peso da prensa</b>
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="body2">{pressWeight} ton</Typography>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default InjectionUnit;
