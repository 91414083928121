import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUserTag } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { ProposalRequestWithMeta } from '../../../../../models/ProposalRequest';
import Button from '../../../../common/Button';
import Customer from '../../../../../models/Customer';
import { machineTypes } from '../../../Machines/sources';

interface IProps {
  customers: Record<Customer['id'], Customer>;
  proposalRequest: ProposalRequestWithMeta;
  toggleProposalRequestView: (proposalRequestId?: number) => void;
}

class ViewModal extends PureComponent<IProps> {
  public render() {
    const { customers, proposalRequest, toggleProposalRequestView } = this.props;
    const {
      id,
      customerId,
      dueDate,
      machineType,
      otherMachineType,
      commercialUserName,
      description,
    } = proposalRequest;

    return (
      <Dialog fullWidth maxWidth="lg" open onClose={() => toggleProposalRequestView()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faUserTag} />
          <span style={{ marginLeft: '8px' }}>Detalhes do pedido de proposta</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleProposalRequestView()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Grid container spacing={1} alignItems="center">
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Prazo de resposta</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{format(new Date(dueDate), 'yyyy-MM-dd')}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Tipo de Máquina</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  {(machineTypes.find((m) => m.id === machineType) || { label: 'Outro' }).label}
                  {machineType === 'other' ? `: ${otherMachineType}` : ''}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Cliente</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{(customers[customerId] || {}).name}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Comercial</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{commercialUserName}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Descrição</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{description}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            <Box mr={1}>
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faPencilAlt} />}
                component={Link}
                to={`/dashboard/customers/proposal-requests/proposalrequestid/${id}/edit`}
              >Editar</Button>
            </Box>
            <Button color="default" variant="contained" onClick={() => toggleProposalRequestView()}>Fechar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
}

export default ViewModal;