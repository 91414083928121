import { takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from '../actions/index';
import {
  fetchUserSaga,
  authenticateSaga,
  recoverPasswordSaga,
  resetPasswordSaga,
  logoutSaga,
} from './authentication';
import {
  fetchCustomersSaga,
  createCustomerSaga,
  editCustomerSaga,
  toggleCustomerSaga,
  createCustomerRepSaga,
  editCustomerRepSaga,
  deleteCustomerRepSaga,
  fetchCustomerProposalsSaga,
  createCustomerProposalSaga,
  editCustomerProposalSaga,
  fetchCustomerProposalRequestsSaga,
  createCustomerProposalRequestSaga,
  editCustomerProposalRequestSaga,
} from './customers';
import { fetchDashboardMetricsSaga } from './dashboard';
import {
  fetchMachinesSaga,
  createMachineSaga,
  editMachineSaga,
  deleteMachineSaga,
  changeMachineCustomerSaga,
  fetchMachinesTicketsSaga,
  createMachineTicketSaga,
  editMachineTicketSaga,
  deleteMachineTicketSaga,
  createMachineTicketTaskSaga,
  editMachineTicketTaskSaga,
  deleteMachineTicketTaskSaga,
  createMachineTicketTaskExpenseSaga,
  deleteMachineTicketTaskExpenseSaga,
  createMachineTicketTaskCommentSaga,
  deleteMachineTicketTaskCommentSaga,
  createMachineTicketTaskSignSaga,
  fetchMachinesForMarketplaceSaga,
  sendMachineContactSaga,
  fetchDashboardTasksSaga,
} from './machines';
import {
  fetchSuppliersSaga,
  createSupplierSaga,
  editSupplierSaga,
  toggleSupplierSaga,
  createSupplierRepSaga,
  editSupplierRepSaga,
  deleteSupplierRepSaga,
} from './suppliers';
import {
  fetchUsersSaga,
  createUserSaga,
  editUserSaga,
  toggleUserSaga,
} from './users';
import {
  fetchZonesTableSaga,
  createTableZoneSaga,
  editTableZoneSaga,
  deleteTableZoneSaga,
  fetchZoneListSaga,
  fetchDynamicFieldValuesSaga,
  editDynamicFieldSaga,
  fetchDynamicFieldValuesByFormSaga,
} from './table';

export default function* saga() {
  yield takeLatest(
    getType(actions.fetchUser.request),
    fetchUserSaga
  );
  yield takeLatest(getType(actions.authenticate.request), authenticateSaga);
  yield takeLatest(getType(actions.recoverPassword.request), recoverPasswordSaga);
  yield takeLatest(getType(actions.resetPassword.request), resetPasswordSaga);
  yield takeLatest(getType(actions.logout.request), logoutSaga);

  yield takeLatest(getType(actions.fetchCustomers.request), fetchCustomersSaga);
  yield takeLatest(getType(actions.createCustomer.request), createCustomerSaga);
  yield takeLatest(getType(actions.editCustomer.request), editCustomerSaga);
  yield takeLatest(getType(actions.toggleCustomer.request), toggleCustomerSaga);
  yield takeLatest(getType(actions.createCustomerRep.request), createCustomerRepSaga);
  yield takeLatest(getType(actions.editCustomerRep.request), editCustomerRepSaga);
  yield takeLatest(getType(actions.deleteCustomerRep.request), deleteCustomerRepSaga);

  yield takeLatest(getType(actions.fetchCustomerProposals.request), fetchCustomerProposalsSaga);
  yield takeLatest(getType(actions.createCustomerProposal.request), createCustomerProposalSaga);
  yield takeLatest(getType(actions.editCustomerProposal.request), editCustomerProposalSaga);

  yield takeLatest(getType(actions.fetchCustomerProposalRequests.request), fetchCustomerProposalRequestsSaga);
  yield takeLatest(getType(actions.createCustomerProposalRequest.request), createCustomerProposalRequestSaga);
  yield takeLatest(getType(actions.editCustomerProposalRequest.request), editCustomerProposalRequestSaga);

  yield takeLatest(getType(actions.fetchDashboardMetrics.request), fetchDashboardMetricsSaga);
  
  yield takeLatest(getType(actions.fetchMachines.request), fetchMachinesSaga);
  yield takeLatest(getType(actions.createMachine.request), createMachineSaga);
  yield takeLatest(getType(actions.editMachine.request), editMachineSaga);
  yield takeLatest(getType(actions.deleteMachine.request), deleteMachineSaga);
  yield takeLatest(getType(actions.changeMachineCustomer.request), changeMachineCustomerSaga);

  yield takeLatest(getType(actions.fetchMachinesTickets.request), fetchMachinesTicketsSaga);
  yield takeLatest(getType(actions.createMachineTicket.request), createMachineTicketSaga);
  yield takeLatest(getType(actions.editMachineTicket.request), editMachineTicketSaga);
  yield takeLatest(getType(actions.deleteMachineTicket.request), deleteMachineTicketSaga);
  yield takeLatest(getType(actions.createMachineTicketTask.request), createMachineTicketTaskSaga);
  yield takeLatest(getType(actions.editMachineTicketTask.request), editMachineTicketTaskSaga);
  yield takeLatest(getType(actions.deleteMachineTicketTask.request), deleteMachineTicketTaskSaga);
  yield takeLatest(getType(actions.createMachineTicketTaskExpense.request), createMachineTicketTaskExpenseSaga);
  yield takeLatest(getType(actions.deleteMachineTicketTaskExpense.request), deleteMachineTicketTaskExpenseSaga);
  yield takeLatest(getType(actions.createMachineTicketTaskComment.request), createMachineTicketTaskCommentSaga);
  yield takeLatest(getType(actions.deleteMachineTicketTaskComment.request), deleteMachineTicketTaskCommentSaga);
  yield takeLatest(getType(actions.createMachineTicketTaskSign.request), createMachineTicketTaskSignSaga);
  yield takeLatest(getType(actions.fetchMachinesForMarketplace.request), fetchMachinesForMarketplaceSaga);
  yield takeLatest(getType(actions.sendMachineContact.request), sendMachineContactSaga);
  yield takeLatest(getType(actions.fetchDashboardTasks.request), fetchDashboardTasksSaga);

  yield takeLatest(getType(actions.fetchSuppliers.request), fetchSuppliersSaga);
  yield takeLatest(getType(actions.createSupplier.request), createSupplierSaga);
  yield takeLatest(getType(actions.editSupplier.request), editSupplierSaga);
  yield takeLatest(getType(actions.toggleSupplier.request), toggleSupplierSaga);
  yield takeLatest(getType(actions.createSupplierRep.request), createSupplierRepSaga);
  yield takeLatest(getType(actions.editSupplierRep.request), editSupplierRepSaga);
  yield takeLatest(getType(actions.deleteSupplierRep.request), deleteSupplierRepSaga);

  yield takeLatest(getType(actions.fetchUsers.request), fetchUsersSaga);
  yield takeLatest(getType(actions.createUser.request), createUserSaga);
  yield takeLatest(getType(actions.editUser.request), editUserSaga);
  yield takeLatest(getType(actions.toggleUser.request), toggleUserSaga);

  yield takeLatest(getType(actions.fetchZonesTable.request), fetchZonesTableSaga);
  yield takeLatest(getType(actions.createTableZone.request), createTableZoneSaga);
  yield takeLatest(getType(actions.editTableZone.request), editTableZoneSaga);
  yield takeLatest(getType(actions.deleteTableZone.request), deleteTableZoneSaga);
  yield takeLatest(getType(actions.fetchZoneList.request), fetchZoneListSaga);
  yield takeLatest(getType(actions.fetchDynamicFieldValues.request), fetchDynamicFieldValuesSaga);
  yield takeLatest(getType(actions.editDynamicField.request), editDynamicFieldSaga);
  yield takeLatest(getType(actions.fetchDynamicFieldValuesByForm.request), fetchDynamicFieldValuesByFormSaga);
};