import { InputAdornment, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { MachineTypes } from '../sources';

interface IProps {
  type: string;

  injectionUnitFuseDiameter?: number;
  injectionUnitMaxRPMFuse?: number;
  injectionUnitVolume?: number;
  injectionUnitMaxWeight?: number;
  injectionUnitPression?: number;
  injectionUnitRatio?: number;
  injectionCenterRingDiameter?: number;
  injectionScrewStrock?: number;
  injectionRate?: number;
  injectionNozzleContactForce?: number;
  injectionNozzleStroke?: number;
  injectionResineSystem?: boolean;
  ndInjectionUnit?: boolean;
  ndInjectionUnitFuseDiameter?: number;
  ndInjectionUnitMaxRPMFuse?: number;
  ndInjectionUnitVolume?: number;
  ndInjectionUnitMaxWeight?: number;
  ndInjectionUnitPression?: number;
  ndInjectionUnitRatio?: number;
  ndInjectionCenterRingDiameter?: number;
  ndInjectionScrewStrock?: number;
  ndInjectionRate?: number;
  ndInjectionNozzleContactForce?: number;
  ndInjectionNozzleStroke?: number;
  ndInjectionResineSystem?: boolean;
  pressDimensionLength?: number;
  pressDimensionWidth?: number;
  pressDimensionHeight?: number;
  pressWeight?: number;

  handleChange: (name: string, value: any) => void;
}

const FormInjectionUnit: FunctionComponent <IProps> = (properties) => {
  const {
    type,

    injectionUnitFuseDiameter,
    injectionUnitMaxRPMFuse,
    injectionUnitVolume,
    injectionUnitMaxWeight,
    injectionUnitPression,
    injectionUnitRatio,
    injectionCenterRingDiameter,
    injectionScrewStrock,
    injectionRate,
    injectionNozzleContactForce,
    injectionNozzleStroke,
    injectionResineSystem,
    ndInjectionUnit,
    ndInjectionUnitFuseDiameter,
    ndInjectionUnitMaxRPMFuse,
    ndInjectionUnitVolume,
    ndInjectionUnitMaxWeight,
    ndInjectionUnitPression,
    ndInjectionUnitRatio,
    ndInjectionCenterRingDiameter,
    ndInjectionScrewStrock,
    ndInjectionRate,
    ndInjectionNozzleContactForce,
    ndInjectionNozzleStroke,
    ndInjectionResineSystem,
    pressDimensionLength,
    pressDimensionWidth,
    pressDimensionHeight,
    pressWeight,

    handleChange,
  } = properties;

  return (
    <>
      <Box pt={2}>
        <Card>
          <CardContent>
            <Box mb={2}>
              <Typography className="subtitle" component="b">Unidade de Injecção</Typography>
            </Box>
            <Grid container spacing={2}>
              {(type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.PLASTIC_INJECTION_VERTICAL) && (
                <>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Diâmetro do fuso"
                        value={injectionUnitFuseDiameter || ''}
                        variant="standard"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionUnitFuseDiameter', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="RPM máximo do fuso"
                        value={injectionUnitMaxRPMFuse || ''}
                        variant="standard"
                        type="number"
                        onChange={(event: any) => handleChange('injectionUnitMaxRPMFuse', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Volume de Injecção"
                        value={injectionUnitVolume || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">cm3</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionUnitVolume', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Peso máximo de injecção (em PS)"
                        value={injectionUnitMaxWeight || ''}
                        variant="standard"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionUnitMaxWeight', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Pressão de injecção"
                        value={injectionUnitPression || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">bar</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionUnitPression', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Racio de injecção (L/D)"
                        value={injectionUnitRatio || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        onChange={(event: any) => handleChange('injectionUnitRatio', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Center Ring Diameter"
                        value={injectionCenterRingDiameter || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionCenterRingDiameter', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Screw Stroke - Curso do fuso"
                        value={injectionScrewStrock || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionScrewStrock', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Injection Rate - Velocidade de Injecção"
                        value={injectionRate || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">cm3/s</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionRate', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Nozzle contact force - Força contato do bico Injecção"
                        value={injectionNozzleContactForce || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">t</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionNozzleContactForce', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Nozzle stroke - Curso carro Injecção"
                        value={injectionNozzleStroke || ''}
                        variant="standard"
                        type="number"
                        inputProps={{ step: 0.01 }}
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('injectionNozzleStroke', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {type === MachineTypes.TRY_OUT_PRESS && (
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={injectionResineSystem || false} />}
                        label="Tem sistema de injecção de resina"
                        onChange={(event: any) => handleChange('injectionResineSystem', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
              <Grid item sm={12}>
                <FormControl margin="dense" fullWidth>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={ndInjectionUnit || false} />}
                      label="Tem 2ª unidade de injeção"
                      onChange={(event: any) => handleChange('ndInjectionUnit', event.target.checked)}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {ndInjectionUnit && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Unidade de Injecção (2)</Typography>
              </Box>
              <Grid container spacing={2}>
                {(type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.PLASTIC_INJECTION_VERTICAL) && (
                  <>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Diâmetro do fuso"
                          value={ndInjectionUnitFuseDiameter || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionUnitFuseDiameter', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="RPM máximo do fuso"
                          value={ndInjectionUnitMaxRPMFuse || ''}
                          variant="standard"
                          type="number"
                          onChange={(event: any) => handleChange('ndInjectionUnitMaxRPMFuse', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Volume de Injecção"
                          value={ndInjectionUnitVolume || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">cm3</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionUnitVolume', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Peso máximo de injecção (em PS)"
                          value={ndInjectionUnitMaxWeight || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionUnitMaxWeight', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Pressão de injecção"
                          value={ndInjectionUnitPression || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">bar</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionUnitPression', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Racio de injecção (L/D)"
                          value={ndInjectionUnitRatio || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          onChange={(event: any) => handleChange('ndInjectionUnitRatio', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Center Ring Diameter"
                          value={ndInjectionCenterRingDiameter || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionCenterRingDiameter', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Screw Stroke - Curso do fuso"
                          value={ndInjectionScrewStrock || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionScrewStrock', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Injection Rate - Velocidade de Injecção"
                          value={ndInjectionRate || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">cm3/s</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionRate', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Nozzle contact force - Força contato do bico Injecção"
                          value={ndInjectionNozzleContactForce || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">t</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionNozzleContactForce', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Nozzle stroke - Curso carro Injecção"
                          value={ndInjectionNozzleStroke || ''}
                          variant="standard"
                          type="number"
                          inputProps={{ step: 0.01 }}
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('ndInjectionNozzleStroke', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                {type === MachineTypes.TRY_OUT_PRESS && (
                  <Grid item sm={6}>
                    <FormControl margin="dense" fullWidth>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox checked={ndInjectionResineSystem || false} />}
                          label="Tem sistema de injecção de resina"
                          onChange={(event: any) => handleChange('ndInjectionResineSystem', event.target.checked)}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {type === MachineTypes.TRY_OUT_PRESS && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Prensa</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="C"
                      value={pressDimensionLength || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                      onChange={(event: any) => handleChange('pressDimensionLength', event.target.value)}
                      helperText="Dimensão da prensa"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="L"
                      value={pressDimensionWidth || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                      onChange={(event: any) => handleChange('pressDimensionWidth', event.target.value)}
                      helperText="Dimensão da prensa"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="A"
                      value={pressDimensionHeight || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                      onChange={(event: any) => handleChange('pressDimensionHeight', event.target.value)}
                      helperText="Dimensão da prensa"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Peso da prensa"
                      value={pressWeight || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">ton</InputAdornment> }}
                      onChange={(event: any) => handleChange('pressWeight', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default FormInjectionUnit;
