import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { coneTypes, coolingType, MachineTypes, spindleNoseTypes, spindleTypes } from '../sources';

interface IProps {
  type: string,

  highPressureInteriorRefrigeration?: boolean;
  highPressureInteriorRefrigerationBar?: number;
  wasteExtractor?: boolean;
  wasteExtractorType?: string;
  toolMeasurer?: boolean;
  toolMeasurerType?: string;
  autoCenter?: boolean;
  autoCenterBrand?: string;
  cabinada?: boolean;
  partCatcher?: boolean;
  toolMeasurerArm?: boolean;
  toolMeasurerArmAutomatic?: string;
  steeringWheelElec?: boolean;
  tornoDiameterTurnable?: number;
  tornoDiameterBush?: number;
  tornoDiameterCar?: number;
  tornoDiameterBarPass?: number;
  spindleRPM?: number;
  spindleMotorPotence?: number;
  spindleDiameter?: number;
  spindleNoseType?: string;
  spindleLiveTools?: boolean;
  spindleLiveToolsQuantity?: number;
  spindleLiveToolsRPM?: number;
  torque?: number;
  coneType?: number;
  spindleType?: string;
  spindleElectro?: boolean;
  spindleElectroBrand?: string;
  spindleElectroCoolerType?: number;
  spindleInterfaceType?: string;
  subSpindle?: boolean;
  subSpindleDiameterTornable?: number;
  subSpindleDiameterBush?: number;
  subSpindleDiameterCar?: number;
  subSpindleDiameterBar?: number;
  subSpindleNoseType?: string;
  subSpindleLiveTools?: boolean;
  subSpindleLiveToolsQuantity?: number;
  subSpindleLiveToolsRPM?: number;
  subSpindleRPM?: number;
  subSpindleMoterPower?: number;
  subSpindleInterfaceType?: string;
  subSpindlePartCatcher?: boolean;
  subSpindleTravelAxisLength?: number;
  contraPonto?: boolean;
  contraPontoType?: string;
  contraPontoPath?: string;
  contraPontoProgrammablePositioning?: boolean;
  grindDimensionX?: number;
  grindDimensionY?: number;
  grindDimensionZ?: number;
  grindTableDistance?: number;
  grindMinVerticalAdd?: number;
  grindRotation?: number;
  gridPotence?: number;
  gridRPM?: number;
  gridMotorPotence?: number;
  gridRectifierAuto?: boolean;
  gridBalanceSystem?: boolean;
  gridFilteringSystem?: boolean;
  gridFilteringSystemVacuum?: boolean;
  gridFilteringSystemPaper?: boolean;
  gridFilteringSystemMagnetic?: boolean;
  gridFilteringSystemOther?: boolean;
  gridFilteringSystemOtherValue?: string;
  gridRectifierMaxDiameter?: number;
  gridCentersHeight?: number;
  gridCentersInternalRetifierDiameterMin?: number;
  gridCentersInternalRetifierDiameterMax?: number;
  gridRetifierAngleMin?: number;
  gridRetifierAngleMax?: number;
  gridMaxPartWeight?: number;
  gridCarCourse?: number;
  gridAutoPassing?: boolean;
  gridCenterHeadType?: string;
  gridCenterContraPontoType?: string;
  gridCenterContraPontoPath?: number;
  gridBalancerIncluded?: boolean;
  closeUnitPlateDimensionH?: number;
  closeUnitPlateDimensionV?: number;
  closeUnitColumnDistanceH?: number;
  closeUnitColumnDistanceV?: number;
  closeUnitMoldTicknessMin?: number;
  closeUnitMoldTicknessMax?: number;
  closeUnitMinMoldDimension?: string;
  closeUnitForce?: number;
  openUnitForce?: number;
  closeUnitSupPlateCourse?: number;
  closeUnitSupPlateMove?: boolean;
  closeUnitMobilePlateCourse?: number;
  closeUnitMaxDistanceBetween?: number;
  closeUnitExtractorQuantity?: number;
  closeUnitExtractorForce?: number;
  closeUnitExtractionCourse?: number;

  handleChange: (name: string, value: any) => void;
}

const FormBarSupplier: FunctionComponent <IProps> = (properties) => {
  const {
    type,

    highPressureInteriorRefrigeration,
    highPressureInteriorRefrigerationBar,
    wasteExtractor,
    wasteExtractorType,
    toolMeasurer,
    toolMeasurerType,
    autoCenter,
    autoCenterBrand,
    cabinada,
    partCatcher,
    toolMeasurerArm,
    toolMeasurerArmAutomatic,
    steeringWheelElec,
    tornoDiameterTurnable,
    tornoDiameterBush,
    tornoDiameterCar,
    tornoDiameterBarPass,
    spindleRPM,
    spindleMotorPotence,
    spindleDiameter,
    spindleNoseType,
    spindleLiveTools,
    spindleLiveToolsQuantity,
    spindleLiveToolsRPM,
    torque,
    coneType,
    spindleType,
    spindleElectro,
    spindleElectroBrand,
    spindleElectroCoolerType,
    spindleInterfaceType,
    subSpindle,
    subSpindleDiameterTornable,
    subSpindleDiameterBush,
    subSpindleDiameterCar,
    subSpindleDiameterBar,
    subSpindleNoseType,
    subSpindleLiveTools,
    subSpindleLiveToolsQuantity,
    subSpindleLiveToolsRPM,
    subSpindleRPM,
    subSpindleMoterPower,
    subSpindleInterfaceType,
    subSpindlePartCatcher,
    subSpindleTravelAxisLength,
    contraPonto,
    contraPontoType,
    contraPontoPath,
    contraPontoProgrammablePositioning,
    grindDimensionX,
    grindDimensionY,
    grindDimensionZ,
    grindTableDistance,
    grindMinVerticalAdd,
    grindRotation,
    gridPotence,
    gridRPM,
    gridMotorPotence,
    gridRectifierAuto,
    gridBalanceSystem,
    gridFilteringSystem,
    gridFilteringSystemVacuum,
    gridFilteringSystemPaper,
    gridFilteringSystemMagnetic,
    gridFilteringSystemOther,
    gridFilteringSystemOtherValue,
    gridRectifierMaxDiameter,
    gridCentersHeight,
    gridCentersInternalRetifierDiameterMin,
    gridCentersInternalRetifierDiameterMax,
    gridRetifierAngleMin,
    gridRetifierAngleMax,
    gridMaxPartWeight,
    gridCarCourse,
    gridAutoPassing,
    gridCenterHeadType,
    gridCenterContraPontoType,
    gridCenterContraPontoPath,
    gridBalancerIncluded,
    closeUnitPlateDimensionH,
    closeUnitPlateDimensionV,
    closeUnitColumnDistanceH,
    closeUnitColumnDistanceV,
    closeUnitMoldTicknessMin,
    closeUnitMoldTicknessMax,
    closeUnitMinMoldDimension,
    closeUnitForce,
    openUnitForce,
    closeUnitSupPlateCourse,
    closeUnitSupPlateMove,
    closeUnitMobilePlateCourse,
    closeUnitMaxDistanceBetween,
    closeUnitExtractorQuantity,
    closeUnitExtractorForce,
    closeUnitExtractionCourse,

    handleChange,
  } = properties;

  return (
    <>
      {(
        type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        type === MachineTypes.HORIZONTAL ||
        type === MachineTypes.BORING ||
        type === MachineTypes.TORNO ||
        type === MachineTypes.SWISS_LATHE
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Características</Typography>
              </Box>

              <Grid container spacing={2}>
                {(type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.BORING ||
                  type === MachineTypes.SWISS_LATHE
                ) && (
                  <>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={highPressureInteriorRefrigeration || false} />}
                            label="Refrigeração Interior de Alta Pressão"
                            onChange={(event: any) => handleChange('highPressureInteriorRefrigeration', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      {highPressureInteriorRefrigeration && (
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Bar"
                            value={highPressureInteriorRefrigerationBar || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            onChange={(event: any) => handleChange('highPressureInteriorRefrigerationBar', event.target.value)}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={wasteExtractor || false} />}
                        label="Extrator de Limalha"
                        onChange={(event: any) => handleChange('wasteExtractor', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  {(
                    type === MachineTypes.T3X ||
                    type === MachineTypes.T5X ||
                    type === MachineTypes.HORIZONTAL ||
                    type === MachineTypes.BORING ||
                    type === MachineTypes.SWISS_LATHE
                  ) && wasteExtractor && (
                    <FormControl margin="dense" fullWidth>
                      <InputLabel id="marchine-extractor">Tipo de Extrator</InputLabel>
                      <Select
                        labelId="marchine-extractor"
                        value={wasteExtractorType || ''}
                        onChange={(event: any) => handleChange('wasteExtractorType', event.target.value)}
                      >
                        <MenuItem value="">Nenhum</MenuItem>
                        <MenuItem value="carpet">Tapete</MenuItem>
                        <MenuItem value="spindle">Fuso</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid container></Grid>
                {(
                  type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.BORING
                ) && (
                  <>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={toolMeasurer || false} />}
                            label="Medidor de Ferramenta"
                            onChange={(event: any) => handleChange('toolMeasurer', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      {toolMeasurer && (
                        <FormControl margin="dense" fullWidth>
                          <InputLabel id="marchine-measurer">Tipo de Medidor</InputLabel>
                          <Select
                            labelId="marchine-measurer"
                            value={toolMeasurerType || ''}
                            onChange={(event: any) => handleChange('toolMeasurerType', event.target.value)}
                          >
                            <MenuItem value="">Nenhum</MenuItem>
                            <MenuItem value="contact">Contacto</MenuItem>
                            <MenuItem value="laser">Laser</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={autoCenter || false} />}
                            label="Palpador de Centragem Automática"
                            onChange={(event: any) => handleChange('autoCenter', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      {autoCenter && (
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Marca"
                            value={autoCenterBrand || ''}
                            variant="standard"
                            inputProps={{ maxLength: 255 }}
                            onChange={(event: any) => handleChange('autoCenterBrand', event.target.value)}
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item sm={12}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={cabinada || false} />}
                            label="Cabinada Completa"
                            onChange={(event: any) => handleChange('cabinada', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {type === MachineTypes.TORNO && (
                  <>
                    <Grid item sm={12}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={partCatcher || false} />}
                            label="Apanhador de peças"
                            onChange={(event: any) => handleChange('partCatcher', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={toolMeasurerArm || false} />}
                            label="Braço medidor de ferramenta"
                            onChange={(event: any) => handleChange('toolMeasurerArm', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      {toolMeasurerArm && (
                        <FormControl margin="dense" fullWidth>
                          <InputLabel id="marchine-measurer-auto">Tipo de Medidor</InputLabel>
                          <Select
                            labelId="marchine-measurer-auto"
                            value={toolMeasurerArmAutomatic}
                            onChange={(event: any) => handleChange('toolMeasurerArmAutomatic', event.target.value)}
                          >
                            <MenuItem value="">Nenhum</MenuItem>
                            <MenuItem value="automatic">Automático</MenuItem>
                            <MenuItem value="semi-automatic">Semi-Automático</MenuItem>
                            <MenuItem value="manual">Manual</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                  </>
                )}
                {(type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.TORNO ||
                  type === MachineTypes.BORING) && (
                    <>
                      <Grid item sm={12}>
                        <FormControl margin="dense" fullWidth>
                          <FormGroup row>
                            <FormControlLabel
                              control={<Checkbox checked={steeringWheelElec || false} />}
                              label="Volante Electrónico"
                              onChange={(event: any) => handleChange('steeringWheelElec', event.target.checked)}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </>
                  )
                }
                {type === MachineTypes.TORNO && (
                  <>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Diâmetro Torneável"
                          value={tornoDiameterTurnable || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tornoDiameterTurnable', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Diâmetro Bucha"
                          value={tornoDiameterBush || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tornoDiameterBush', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Diâmetro Sobre o Carro"
                          value={tornoDiameterCar || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tornoDiameterCar', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Passagem de Barra"
                          value={tornoDiameterBarPass || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('tornoDiameterBarPass', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {(
        type === MachineTypes.T3X ||
        type === MachineTypes.T5X ||
        type === MachineTypes.HORIZONTAL ||
        type === MachineTypes.TORNO ||
        type === MachineTypes.TORNOMULTITASKS ||
        type === MachineTypes.BORING ||
        type === MachineTypes.SWISS_LATHE
      ) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Spindle</Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="RPM"
                      value={spindleRPM || ''}
                      variant="standard"
                      type="number"
                      onChange={(event: any) => handleChange('spindleRPM', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Potência de Motor"
                      value={spindleMotorPotence || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">kw</InputAdornment> }}
                      onChange={(event: any) => handleChange('spindleMotorPotence', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                {(type === MachineTypes.BORING || type === MachineTypes.SWISS_LATHE) && (
                  <Grid item sm={3}>
                    <FormControl margin="dense" fullWidth>
                      <TextField
                        label="Diâmetro do spindle"
                        value={spindleDiameter || ''}
                        variant="standard"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                        onChange={(event: any) => handleChange('spindleDiameter', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )}
                {(type === MachineTypes.SWISS_LATHE) && (
                  <>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="marchine-spindlenosetype">Tipo de Spindle Nose</InputLabel>
                        <Select
                          labelId="marchine-spindlenosetype"
                          value={spindleNoseType || ''}
                          onChange={(event: any) => handleChange('spindleNoseType', event.target.value)}
                        >
                          <MenuItem value="">Nenhum</MenuItem>
                          {spindleNoseTypes.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={spindleLiveTools || false} />}
                            label="Spindle Live tools"
                            onChange={(event: any) => handleChange('spindleLiveTools', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    {spindleLiveTools && (
                      <>
                        <Grid item sm={3}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Quantidade"
                              value={spindleLiveToolsQuantity || ''}
                              variant="standard"
                              type="number"
                              onChange={(event: any) => handleChange('spindleLiveToolsQuantity', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={3}>
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="RPM"
                              value={spindleLiveToolsRPM || ''}
                              variant="standard"
                              type="number"
                              onChange={(event: any) => handleChange('spindleLiveToolsRPM', event.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid container />
                  </>
                )}
                {(
                  type === MachineTypes.T3X ||
                  type === MachineTypes.T5X ||
                  type === MachineTypes.HORIZONTAL ||
                  type === MachineTypes.TORNOMULTITASKS ||
                  type === MachineTypes.BORING
                ) && (
                  <>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Torque"
                          value={torque || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">Nm</InputAdornment> }}
                          onChange={(event: any) => handleChange('torque', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="marchine-conetype">Tipo de Cone</InputLabel>
                        <Select
                          labelId="marchine-conetype"
                          value={coneType || ''}
                          onChange={(event: any) => handleChange('coneType', event.target.value)}
                        >
                          <MenuItem value="">Nenhum</MenuItem>
                          {coneTypes.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="marchine-spindletype">Tipo de Spindle</InputLabel>
                        <Select
                          labelId="marchine-spindletype"
                          value={spindleType || ''}
                          onChange={(event: any) => handleChange('spindleType', event.target.value)}
                        >
                          <MenuItem value="">Nenhum</MenuItem>
                          {spindleTypes.filter((c) => !c.type || c.type === type).map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <InputLabel id="marchine-coolertype">Tipo de Refrigerador Spindle</InputLabel>
                        <Select
                          labelId="marchine-coolertype"
                          value={spindleElectroCoolerType || ''}
                          onChange={(event: any) => handleChange('spindleElectroCoolerType', event.target.value)}
                        >
                          <MenuItem value="">Nenhum</MenuItem>
                          {coolingType.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Tipo de Interface"
                            value={spindleInterfaceType || ''}
                            variant="standard"
                            inputProps={{ maxLength: 255 }}
                            onChange={(event: any) => handleChange('spindleInterfaceType', event.target.value)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid container></Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={spindleElectro || false} />}
                            label="Spindle Electromandrino"
                            onChange={(event: any) => handleChange('spindleElectro', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      {spindleElectro && (
                        <FormControl margin="dense" fullWidth>
                          <TextField
                            label="Marca"
                            value={spindleElectroBrand || ''}
                            variant="standard"
                            inputProps={{ maxLength: 255 }}
                            onChange={(event: any) => handleChange('spindleElectroBrand', event.target.value)}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </>
                )}
                {(type === MachineTypes.TORNO || type === MachineTypes.SWISS_LATHE) && (
                  <>
                    <Grid item sm={3}>
                      <FormControl component="fieldset" fullWidth margin="dense">
                        <FormLabel component="legend">Sub Spindle</FormLabel>
                        <RadioGroup
                          value={subSpindle !== undefined ? String(subSpindle || false) : undefined}
                          onChange={(event: any) => handleChange('subSpindle', event.target.value === 'true')}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel value="true" control={<Radio />} label="Sim" />
                          <FormControlLabel value="false" control={<Radio />} label="Nao" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {subSpindle ? (
                      <>
                        <Grid item sm={3}>
                          <FormControl margin="dense" fullWidth>
                            <FormGroup>
                              <TextField
                                label="Diâmetro Torneável"
                                value={subSpindleDiameterTornable || ''}
                                variant="standard"
                                type="number"
                                InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                                onChange={(event: any) => handleChange('subSpindleDiameterTornable', event.target.value)}
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        {type !== MachineTypes.SWISS_LATHE && (
                          <>
                            <Grid item sm={6}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="Diâmetro da Bucha"
                                    value={subSpindleDiameterBush || ''}
                                    variant="standard"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                                    onChange={(event: any) => handleChange('subSpindleDiameterBush', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="Diâmetro Sobre o Carro"
                                    value={subSpindleDiameterCar || ''}
                                    variant="standard"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                                    onChange={(event: any) => handleChange('subSpindleDiameterCar', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="Passagem de Barra"
                                    value={subSpindleDiameterBar || ''}
                                    variant="standard"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                                    onChange={(event: any) => handleChange('subSpindleDiameterBar', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="RPM"
                                    value={subSpindleRPM || ''}
                                    variant="standard"
                                    type="number"
                                    onChange={(event: any) => handleChange('subSpindleRPM', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="Potência Motor"
                                    value={subSpindleMoterPower || ''}
                                    variant="standard"
                                    type="number"
                                    inputProps={{ step: 0.01 }}
                                    InputProps={{ endAdornment: <InputAdornment position="end">kw</InputAdornment> }}
                                    onChange={(event: any) => handleChange('subSpindleMoterPower', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="Tipo de Interface"
                                    value={subSpindleInterfaceType || ''}
                                    variant="standard"
                                    inputProps={{ maxLength: 255 }}
                                    onChange={(event: any) => handleChange('subSpindleInterfaceType', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={<Checkbox checked={subSpindlePartCatcher || false} />}
                                    label="Apanhador de Peças no Sub Spindle"
                                    onChange={(event: any) => handleChange('subSpindlePartCatcher', event.target.checked)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {(type === MachineTypes.SWISS_LATHE) && (
                          <>
                            <Grid item sm={3}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="Sub Spindle Comprimento do Eixo de Viagem"
                                    value={subSpindleTravelAxisLength || ''}
                                    variant="standard"
                                    type="number"
                                    InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                                    onChange={(event: any) => handleChange('subSpindleTravelAxisLength', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                              <FormControl margin="dense" fullWidth>
                                <InputLabel id="marchine-subspindlenosetype">Tipo de Sub Spindle Nose</InputLabel>
                                <Select
                                  labelId="marchine-subspindlenosetype"
                                  value={subSpindleNoseType || ''}
                                  onChange={(event: any) => handleChange('subSpindleNoseType', event.target.value)}
                                >
                                  <MenuItem value="">Nenhum</MenuItem>
                                  {spindleNoseTypes.map((c) => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={<Checkbox checked={subSpindleLiveTools || false} />}
                                    label="Sub Spindle Live Tools"
                                    onChange={(event: any) => handleChange('subSpindleLiveTools', event.target.checked)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            {subSpindleLiveTools && (
                              <>
                                <Grid item sm={3}>
                                  <FormControl margin="dense" fullWidth>
                                    <TextField
                                      label="Quantidade"
                                      value={subSpindleLiveToolsQuantity || ''}
                                      variant="standard"
                                      type="number"
                                      onChange={(event: any) => handleChange('subSpindleLiveToolsQuantity', event.target.value)}
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item sm={3}>
                                  <FormControl margin="dense" fullWidth>
                                    <TextField
                                      label="RPM"
                                      value={subSpindleLiveToolsRPM || ''}
                                      variant="standard"
                                      type="number"
                                      onChange={(event: any) => handleChange('subSpindleLiveToolsRPM', event.target.value)}
                                    />
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Grid container />
                        <Grid item sm={3}>
                          <FormControl component="fieldset" fullWidth margin="dense">
                            <FormLabel component="legend">Contra Ponto</FormLabel>
                            <RadioGroup
                              value={contraPonto !== undefined ? String(contraPonto || false) : undefined}
                              onChange={(event: any) => handleChange('contraPonto', event.target.value === 'true')}
                              style={{ flexDirection: 'row' }}
                            >
                              <FormControlLabel value="true" control={<Radio />} label="Sim" />
                              <FormControlLabel value="false" control={<Radio />} label="Nao" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {contraPonto && (
                          <>
                            <Grid item sm={3}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="Tipo de Contraponto"
                                    value={contraPontoType || ''}
                                    variant="standard"
                                    inputProps={{ maxLength: 255 }}
                                    onChange={(event: any) => handleChange('contraPontoType', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup>
                                  <TextField
                                    label="Curso do Contraponto"
                                    value={contraPontoPath || ''}
                                    variant="standard"
                                    inputProps={{ maxLength: 255 }}
                                    onChange={(event: any) => handleChange('contraPontoPath', event.target.value)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                            <Grid item sm={3}>
                              <FormControl margin="dense" fullWidth>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={<Checkbox checked={contraPontoProgrammablePositioning || false} />}
                                    label="Posicionamento Programável"
                                    onChange={(event: any) => handleChange('contraPontoProgrammablePositioning', event.target.checked)}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {(type === MachineTypes.FLAT_GRINDING || type === MachineTypes.ROUND_GRINDING) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Mó</Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="X"
                      value={grindDimensionX || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('grindDimensionX', event.target.value)}
                      helperText="Dimensão da Mó"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Y"
                      value={grindDimensionY || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('grindDimensionY', event.target.value)}
                      helperText="Dimensão da Mó"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="X"
                      value={grindDimensionZ || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('grindDimensionZ', event.target.value)}
                      helperText="Dimensão da Mó"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Distância da Mó à Mesa"
                      value={grindTableDistance || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('grindTableDistance', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Incremento Mínimo Vertical"
                      value={grindMinVerticalAdd || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('grindMinVerticalAdd', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Rotação da Mó"
                      value={grindRotation || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                      onChange={(event: any) => handleChange('grindRotation', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Potência da Mó"
                      value={gridPotence || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">Kw</InputAdornment> }}
                      onChange={(event: any) => handleChange('gridPotence', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="RPM da Mó"
                      value={gridRPM || ''}
                      variant="standard"
                      type="number"
                      onChange={(event: any) => handleChange('gridRPM', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Potência do Motor da Mó"
                      value={gridMotorPotence || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">Kw</InputAdornment> }}
                      onChange={(event: any) => handleChange('gridMotorPotence', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={gridRectifierAuto || false} />}
                        label="Tem retificador de mó automático"
                        onChange={(event: any) => handleChange('gridRectifierAuto', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={gridBalanceSystem || false} />}
                        label="Tem sistema de balanceador de mó"
                        onChange={(event: any) => handleChange('gridBalanceSystem', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={gridFilteringSystem || false} />}
                        label="Tem sistema de filtragem"
                        onChange={(event: any) => handleChange('gridFilteringSystem', event.target.checked)}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                {gridFilteringSystem && (
                  <Grid item sm={6}>
                    <Grid container spacing={2}>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormGroup row>
                            <FormControlLabel
                              control={<Checkbox checked={gridFilteringSystemVacuum || false} />}
                              label="Aspiração"
                              onChange={(event: any) => handleChange('gridFilteringSystemVacuum', event.target.checked)}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormGroup row>
                            <FormControlLabel
                              control={<Checkbox checked={gridFilteringSystemPaper || false} />}
                              label="Filtro de Papel"
                              onChange={(event: any) => handleChange('gridFilteringSystemPaper', event.target.checked)}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormGroup row>
                            <FormControlLabel
                              control={<Checkbox checked={gridFilteringSystemMagnetic || false} />}
                              label="Magnético"
                              onChange={(event: any) => handleChange('gridFilteringSystemMagnetic', event.target.checked)}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item sm={3}>
                        <FormControl margin="dense" fullWidth>
                          <FormGroup row>
                            <FormControlLabel
                              control={<Checkbox checked={gridFilteringSystemOther || false} />}
                              label="Outro"
                              onChange={(event: any) => handleChange('gridFilteringSystemOther', event.target.checked)}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item sm={12}>
                        {gridFilteringSystemOther && (
                          <FormControl margin="dense" fullWidth>
                            <TextField
                              label="Qual?"
                              value={gridFilteringSystemOtherValue || ''}
                              variant="standard"
                              onChange={(event: any) => handleChange('gridFilteringSystemOtherValue', event.target.value)}
                            />
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid container />
                {type === MachineTypes.ROUND_GRINDING && (
                  <>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Diâmetro máximo de retificação"
                            value={gridRectifierMaxDiameter || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridRectifierMaxDiameter', event.target.value)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Altura de Centros"
                            value={gridCentersHeight || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridCentersHeight', event.target.value)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Min"
                            value={gridCentersInternalRetifierDiameterMin || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridCentersInternalRetifierDiameterMin', event.target.value)}
                            helperText="Diâmetro de Retificação Interna"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Max"
                            value={gridCentersInternalRetifierDiameterMax || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridCentersInternalRetifierDiameterMax', event.target.value)}
                            helperText="Diâmetro de Retificação Interna"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Min"
                            value={gridRetifierAngleMin || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridRetifierAngleMin', event.target.value)}
                            helperText="Ângulo de Retificação"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Max"
                            value={gridRetifierAngleMax || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridRetifierAngleMax', event.target.value)}
                            helperText="Ângulo de Retificação"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Peso Máximo da Peça"
                            value={gridMaxPartWeight || ''}
                            variant="standard"
                            type="number"
                            inputProps={{ step: 0.01 }}
                            InputProps={{ endAdornment: <InputAdornment position="end">Kg</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridMaxPartWeight', event.target.value)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Curso carro da mó"
                            value={gridCarCourse || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridCarCourse', event.target.value)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={gridAutoPassing || false} />}
                            label="Passagem automática"
                            onChange={(event: any) => handleChange('gridAutoPassing', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Tipo de Centro Cabeça"
                            value={gridCenterHeadType || ''}
                            variant="standard"
                            inputProps={{ maxLength: 255 }}
                            onChange={(event: any) => handleChange('gridCenterHeadType', event.target.value)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Tipo de Centro Contra Ponto"
                            value={gridCenterContraPontoType || ''}
                            variant="standard"
                            inputProps={{ maxLength: 255 }}
                            onChange={(event: any) => handleChange('gridCenterContraPontoType', event.target.value)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup>
                          <TextField
                            label="Curso do Centro Contra Ponto"
                            value={gridCenterContraPontoPath || ''}
                            variant="standard"
                            type="number"
                            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                            onChange={(event: any) => handleChange('gridCenterContraPontoPath', event.target.value)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={gridBalancerIncluded || false} />}
                            label="Incluí sistema de balanceamento da mó"
                            onChange={(event: any) => handleChange('gridBalancerIncluded', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {(type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.PLASTIC_INJECTION_VERTICAL || type === MachineTypes.TRY_OUT_PRESS) && (
        <Box pt={2}>
          <Card>
            <CardContent>
              <Box mb={2}>
                <Typography className="subtitle" component="b">Unidade de Fecho</Typography>
              </Box>
              <Grid container spacing={2}>
                {(type === MachineTypes.PLASTIC_INJECTION || type === MachineTypes.PLASTIC_INJECTION_VERTICAL) && (
                  <>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Horizontal"
                          value={closeUnitPlateDimensionH || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('closeUnitPlateDimensionH', event.target.value)}
                          helperText="Dimensão dos pratos"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Vertical"
                          value={closeUnitPlateDimensionV || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('closeUnitPlateDimensionV', event.target.value)}
                          helperText="Dimensão dos pratos"
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Horizontal"
                      value={closeUnitColumnDistanceH || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitColumnDistanceH', event.target.value)}
                      helperText="Distância entre colunas"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Vertical"
                      value={closeUnitColumnDistanceV || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitColumnDistanceV', event.target.value)}
                      helperText="Distância entre colunas"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Min"
                      value={closeUnitMoldTicknessMin || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitMoldTicknessMin', event.target.value)}
                      helperText="Espessura do Molde"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Max"
                      value={closeUnitMoldTicknessMax || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitMoldTicknessMax', event.target.value)}
                      helperText="Espessura do Molde"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Dimensão Mínima do Molde"
                      value={closeUnitMinMoldDimension || ''}
                      variant="standard"
                      onChange={(event: any) => handleChange('closeUnitMinMoldDimension', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid container />
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Força de Fecho"
                      value={closeUnitForce || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">ton</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitForce', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Força de Abertura"
                      value={openUnitForce || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">ton</InputAdornment> }}
                      onChange={(event: any) => handleChange('openUnitForce', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid container />
                {type === MachineTypes.TRY_OUT_PRESS && (
                  <>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <TextField
                          label="Curso de abertura do prato superior"
                          value={closeUnitSupPlateCourse || ''}
                          variant="standard"
                          type="number"
                          InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                          onChange={(event: any) => handleChange('closeUnitSupPlateCourse', event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                      <FormControl margin="dense" fullWidth>
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={closeUnitSupPlateMove || false} />}
                            label="Prato superior inclina"
                            onChange={(event: any) => handleChange('closeUnitSupPlateMove', event.target.checked)}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </>
                )}
                <Grid container />
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Curso de abertura do prato móvel"
                      value={closeUnitMobilePlateCourse || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitMobilePlateCourse', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Distância máxima entre prato móvel e fixo"
                      value={closeUnitMaxDistanceBetween || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitMaxDistanceBetween', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid container />
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Quantidade de Extratores"
                      value={closeUnitExtractorQuantity || ''}
                      variant="standard"
                      type="number"
                      onChange={(event: any) => handleChange('closeUnitExtractorQuantity', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Força do extractor"
                      value={closeUnitExtractorForce || ''}
                      variant="standard"
                      type="number"
                      inputProps={{ step: 0.01 }}
                      InputProps={{ endAdornment: <InputAdornment position="end">kN</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitExtractorForce', event.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl margin="dense" fullWidth>
                    <TextField
                      label="Curso de extração"
                      value={closeUnitExtractionCourse || ''}
                      variant="standard"
                      type="number"
                      InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                      onChange={(event: any) => handleChange('closeUnitExtractionCourse', event.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
}

export default FormBarSupplier;
