import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  fetchCustomers,
  toggleProposalRequestView,
} from '../../../../../actions/index';
import { IAppState } from '../../../../../reducers/index';
import List from './List';

const mapStateToProps = ({ customers }: IAppState) => ({
  loading: customers.proposalRequests.loading,
  proposalRequests: Object.values(customers.proposalRequests.proposalRequests),
  view: customers.proposalRequests.view.proposalRequestId,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleProposalRequestView: (proposalRequestId?: number) =>
      dispatch(toggleProposalRequestView(proposalRequestId)),
    fetchCustomers: () => dispatch(fetchCustomers.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);
