import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CreateIcon from '@material-ui/icons/Create';
import DetailsIcon from '@material-ui/icons/Details';
import EvStationIcon from '@material-ui/icons/EvStation';
import InfoIcon from '@material-ui/icons/Info';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NearMeIcon from '@material-ui/icons/NearMe';
import SaveIcon from '@material-ui/icons/Save';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import className from 'classname';
import { getType } from 'mime';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';

import { CreateMachine, EditMachine } from '../../../../actions/machines';
import { DynamicValue } from '../../../../models/DynamicField';
import APIFile from '../../../../models/File';
import Machine, { MachineOption } from '../../../../models/Machine';
import Supplier from '../../../../models/Supplier';
import ExtendedFile from '../../../../models/File';
import axios from '../../../../api/axios';
import Button from '../../../common/Button';
import { MachineTypes, machineTypes } from '../sources';
// import { testData } from './data.test';

import FormInfo from './Form-Info';
import FormInfoCosts from './Form-Info-Costs';
import FormInfoPrices from './Form-Info-Prices';
import FormDetails from './Form-Details';
import FormTurreta from './Form-Turreta';
import FormFuses from './Form-Fuses';
import FormBarSupplier from './Form-BarSupplier';
import FormErosionHead from './Form-ErosionHead';
import FormGenerator from './Form-Generator';
import FormInjectionUnit from './Form-InjectionUnit';
import FormLogistics from './Form-Logistics';
import FormOptionals from './Form-Optionals';

import './Form.css';

interface IProps {
  createMachine: (properties: CreateMachine) => void;
  machine?: Machine;
  machines: Machine[];
  machineStatus: DynamicValue[];
  editMachine: (properties: EditMachine) => void;
  fetchSuppliers: () => void;
  history: any;
  loading: boolean;
  redirectReady: boolean;
  suppliers: Supplier[];
  isNew?: boolean;
}
interface IState {
  id?: number;
  type: string;
  inited: boolean;

  // Info Step
  description?: string;
  supplierId?: number;
  customerId?: number;
  brand?: string;
  model?: string;
  serialnumber?: string;
  commandType?: string;
  commandBrand?: string;
  commandModel?: string;
  isMachineNew?: boolean;
  buyPrice?: number;
  installBuyPrice?: number;
  truckBuyPrice?: number;
  machineYear?: number;
  machineUsageHours?: number;
  status?: number;
  // ---Only for ts verification
  costsWarrantyMaterial?: number;
  costsWarrantyTravels?: number;
  costsWarrantyManHours?: number;
  costsWarrantyOthers?: number;
  costsSellMaterial?: number;
  costsSellTravels?: number;
  costsSellManHours?: number;
  costsSellOthers?: number;
  costsInvoicingMaterial?: number;
  costsInvoicingTravels?: number;
  costsInvoicingManHours?: number;
  costsInvoicingOthers?: number;
  // ---
  costsOthersMaterial?: number;
  costsOthersTravels?: number;
  costsOthersManHours?: number;
  costsOthersOthers?: number;

  // Details Step
  courseX?: number;
  courseY?: number;
  courseZ?: number;
  course4Axis?: number;
  course4AxisMM?: number;
  course5Axis?: number;
  axisType?: string;
  positioning4Axis?: string;
  positioning5Axis?: string;
  autoPalletExchange?: boolean;
  autoPalletExchangeQuantity?: number;
  autoPalletExchangeDimension?: number;

  tableMeasurement: string;
  tableDimensionX?: number;
  tableDimensionY?: number;
  tableDimensionDiameter?: number;
  spindleTableDistance?: number;
  spindleTableDistanceMin?: number;
  spindleTableDistanceMax?: number;
  spindleTableWeight?: number;
  rotativeTable?: boolean;
  rotativeTableAngleMinPositioning?: number;
  robot?: boolean;
  robotNumberOfEletrods?: number;
  robotNumberOfPallets?: number;
  robotNumberOfAxis?: number;
  robotReach?: number;
  tankDimensionLength?: number;
  tankDimensionWidth?: number;
  tankDimensionHeight?: number;
  tankSubmersible?: boolean;
  tankSubmersibleAuto?: boolean;
  portableSteeringWheel?: boolean;
  partMaxHeight?: number;
  dielectricQuantity?: number;
  hasCorePullers?: boolean;
  corePullersSideMobile?: boolean;
  corePullersSideFixed?: boolean;
  corePullersQuantity?: string;
  hasPneumaticValves?: boolean;
  pneumaticValvesSideMobile?: boolean;
  pneumaticValvesSideFixed?: boolean;
  pneumaticValvesQuantity?: string;
  hasControlValveGate?: boolean;
  controlValveGateQuantity?: number;
  hasMoldTemperatureControl?: boolean;
  moldTemperatureControlZones?: number;
  maxHydricPression?: number;
  heatingPower?: number;
  oilTankCapacity?: number;
  motorPump?: number;
  motorPower?: number;
  tempControlZones?: number;
  magneticTableDimensionLength?: number;
  magneticTableDimensionWidth?: number;
  magneticTableWeight?: number;
  rapidAxisVelocityX?: number;
  rapidAxisVelocityY?: number;
  rapidAxisVelocityZ?: number;
  programmableVelocityAxisX?: number;
  programmableVelocityAxisY?: number;
  programmableVelocityAxisZ?: number;
  rapidAxisVelocityC?: number;
  programmableVelocityAxisC?: number;
  rapidAxisVelocityB?: number;
  programmableVelocityAxisB?: number;
  transmissionMotor4Axis?: string;
  transmissionMotor5Axis?: string;
  cutMaxVelocity?: number;
  cutMaxAngle?: number;
  cutMaxAngleByLength?: number;
  turningDiameter?: number;
  turningLength?: number;
  detailsRPM?: number;
  detailsPotence?: number;
  detailsTorque?: number;
  detailsConeType?: number;
  headtype?: string;
  guidesWidth?: number;
  RPMAxisC?: number;
  maximumFeedrate?: number;
  connectionPower?: number;

  // Turreta Step
  tornTurret?: boolean;
  toolQuantity?: number;
  warehouseType?: number;
  tollExchangeTime?: number;
  supportType?: string;
  staticSupportNumber?: number;
  motorTool?: boolean;
  motorToolRPM?: number;
  motorToolPotence?: number;
  motorToolSupports?: string;
  motorToolNew?: boolean;
  motorToolNewSupportName?: string;
  motorToolNewSupportDescription?: string;
  motorToolNewSupportBuyPrice?: number;
  motorToolNewSupportSellPrice?: number;
  motorToolRadQuantity?: number;
  motorToolAxiQuantity?: number;
  electrodeWarehouse?: boolean;
  electrodeWarehouseQuantity?: number;
  electrodeMaxHeight?: number;
  electrodeMaxErosionHead?: number;

  // Fuses Step
  highPressureInteriorRefrigeration?: boolean;
  highPressureInteriorRefrigerationBar?: number;
  wasteExtractor?: boolean;
  wasteExtractorType?: string;
  toolMeasurer?: boolean;
  toolMeasurerType?: string;
  autoCenter?: boolean;
  autoCenterBrand?: string;
  cabinada?: boolean;
  partCatcher?: boolean;
  toolMeasurerArm?: boolean;
  toolMeasurerArmAutomatic?: string;
  steeringWheelElec?: boolean;
  tornoDiameterTurnable?: number;
  tornoDiameterBush?: number;
  tornoDiameterCar?: number;
  tornoDiameterBarPass?: number;
  spindleRPM?: number;
  spindleMotorPotence?: number;
  spindleDiameter?: number;
  spindleNoseType?: string;
  spindleLiveTools?: boolean;
  spindleLiveToolsQuantity?: number;
  spindleLiveToolsRPM?: number;
  torque?: number;
  coneType?: number;
  spindleType?: string;
  spindleElectro?: boolean;
  spindleElectroBrand?: string;
  spindleElectroCoolerType?: number;
  spindleInterfaceType?: string;
  subSpindle?: boolean;
  subSpindleDiameterTornable?: number;
  subSpindleDiameterBush?: number;
  subSpindleDiameterCar?: number;
  subSpindleDiameterBar?: number;
  subSpindleNoseType?: string;
  subSpindleLiveTools?: boolean;
  subSpindleLiveToolsQuantity?: number;
  subSpindleLiveToolsRPM?: number;
  subSpindleRPM?: number;
  subSpindleMoterPower?: number;
  subSpindleInterfaceType?: string;
  subSpindlePartCatcher?: boolean;
  subSpindleTravelAxisLength?: number;
  contraPonto?: boolean;
  contraPontoType?: string;
  contraPontoPath?: string;
  contraPontoProgrammablePositioning?: boolean;
  grindDimensionX?: number;
  grindDimensionY?: number;
  grindDimensionZ?: number;
  grindTableDistance?: number;
  grindMinVerticalAdd?: number;
  grindRotation?: number;
  gridPotence?: number;
  gridRPM?: number;
  gridMotorPotence?: number;
  gridRectifierAuto?: boolean;
  gridBalanceSystem?: boolean;
  gridFilteringSystem?: boolean;
  gridFilteringSystemVacuum?: boolean;
  gridFilteringSystemPaper?: boolean;
  gridFilteringSystemMagnetic?: boolean;
  gridFilteringSystemOther?: boolean;
  gridFilteringSystemOtherValue?: string;
  gridRectifierMaxDiameter?: number;
  gridCentersHeight?: number;
  gridCentersInternalRetifierDiameterMin?: number;
  gridCentersInternalRetifierDiameterMax?: number;
  gridRetifierAngleMin?: number;
  gridRetifierAngleMax?: number;
  gridMaxPartWeight?: number;
  gridCarCourse?: number;
  gridAutoPassing?: boolean;
  gridCenterHeadType?: string;
  gridCenterContraPontoType?: string;
  gridCenterContraPontoPath?: number;
  gridBalancerIncluded?: boolean;
  closeUnitPlateDimensionH?: number;
  closeUnitPlateDimensionV?: number;
  closeUnitColumnDistanceH?: number;
  closeUnitColumnDistanceV?: number;
  closeUnitMoldTicknessMin?: number;
  closeUnitMoldTicknessMax?: number;
  closeUnitMinMoldDimension?: string;
  closeUnitForce?: number;
  openUnitForce?: number;
  closeUnitSupPlateCourse?: number;
  closeUnitSupPlateMove?: boolean;
  closeUnitMobilePlateCourse?: number;
  closeUnitMaxDistanceBetween?: number;
  closeUnitExtractorQuantity?: number;
  closeUnitExtractorForce?: number;
  closeUnitExtractionCourse?: number;

  // Bar supplier Step
  barSupplierBrand?: string;
  barSupplierModel?: string;
  barSupplierMaxDiameter?: number;
  barSupplierMaxHeight?: number;
  barSupplierDimentionC?: number;
  barSupplierDimentionL?: number;
  barSupplierDimentionA?: number;
  barSupplierWeight?: number;
  
  // Erosion Step
  erosionHeadAxisC360?: boolean;
  erosionHeadType?: string;
  erosionHeadSubType?: string;
  erosionThreadMin?: number;
  erosionThreadMax?: number;
  erosionThreadingAuto?: boolean;
  erosionRodMin?: number;
  erosionRodMax?: number;
  erosionRodAuto?: boolean;
  sawType?: string;
  sawVelocityVariation?: boolean;
  sawVelocityVariationIMin?: number;
  sawVelocityVariationIMax?: number;
  sawAutoSupply?: boolean;
  sawCutDimensionsMinDiameter?: number;
  sawCutDimensionsMinRectangle?: number;
  sawCutDimensionsMaxDiameter?: number;
  sawCutDimensionsMaxRectangle?: number;
  sawCutMinLength?: number;
  sawCutMaxLength?: number;
  sawAngleCut?: boolean;
  sawAngleCutMin?: number;
  sawAngleCutMax?: number;
  sawTapeDimensionsC?: number;
  sawTapeDimensionsL?: number;
  sawTapeDimensionsA?: number;
  sawDimensions?: number;
  sawWeight?: number;

  // Generator Step
  generatorHasRefrigerator?: boolean;
  generatorAMP?: number;

  // Injection Unit
  injectionUnitFuseDiameter?: number;
  injectionUnitMaxRPMFuse?: number;
  injectionUnitVolume?: number;
  injectionUnitMaxWeight?: number;
  injectionUnitPression?: number;
  injectionUnitRatio?: number;
  injectionCenterRingDiameter?: number;
  injectionScrewStrock?: number;
  injectionRate?: number;
  injectionNozzleContactForce?: number;
  injectionNozzleStroke?: number;
  injectionResineSystem?: boolean;
  ndInjectionUnit?: boolean;
  ndInjectionUnitFuseDiameter?: number;
  ndInjectionUnitMaxRPMFuse?: number;
  ndInjectionUnitVolume?: number;
  ndInjectionUnitMaxWeight?: number;
  ndInjectionUnitPression?: number;
  ndInjectionUnitRatio?: number;
  ndInjectionCenterRingDiameter?: number;
  ndInjectionScrewStrock?: number;
  ndInjectionRate?: number;
  ndInjectionNozzleContactForce?: number;
  ndInjectionNozzleStroke?: number;
  ndInjectionResineSystem?: boolean;
  pressDimensionLength?: number;
  pressDimensionWidth?: number;
  pressDimensionHeight?: number;
  pressWeight?: number;

  // Logistics Step
  dimensionC?: number;
  dimensionL?: number;
  dimensionA?: number;
  weight?: number;
  deliveryDate?: number;
  warranty?: string;
  transportPrice?: number;
  paymentConditions?: string;
  commands?: string;

  // Optionals Step
  observations?: string;
  youtubeLink?: string;
  catalog?: string;
  catalogOriginalname?: string;
  catalogFilename?: string;
  filesLoading: boolean;
  files: ExtendedFile[];
  marketplaceVisibility?: boolean;
  marketplaceEmphasis?: boolean;
  marketplacePriceVisibillity?: boolean;
  marketplacePriceType?: 'installPrice' | 'truckPrice';
  options: MachineOption[];

  submitted: boolean;
  dirty: boolean;

  step: string;
}

export const steps = [
  {
    id: 'info',
    icon: <InfoIcon />,
    label: 'Informações', 
    present: [
      MachineTypes.T3X,
      MachineTypes.T5X,
      MachineTypes.HORIZONTAL,
      MachineTypes.TORNO,
      MachineTypes.TORNOMULTITASKS,
      MachineTypes.BORING,
      MachineTypes.EDM,
      MachineTypes.EDM_WIRE,
      MachineTypes.EDM_HOLE,
      MachineTypes.SWISS_LATHE,
      MachineTypes.FLAT_GRINDING,
      MachineTypes.ROUND_GRINDING,
      MachineTypes.PLASTIC_INJECTION,
      MachineTypes.PLASTIC_INJECTION_VERTICAL,
      MachineTypes.BANDSAW,
      MachineTypes.TRY_OUT_PRESS
    ]
  },
  {
    id: 'fuses',
    icon: <SettingsInputComponentIcon />,
    label: 'Unidade de fecho',
    present: [
      MachineTypes.T3X,
      MachineTypes.T5X,
      MachineTypes.HORIZONTAL,
      MachineTypes.TORNO,
      MachineTypes.TORNOMULTITASKS,
      MachineTypes.BORING,
      MachineTypes.SWISS_LATHE,
      MachineTypes.FLAT_GRINDING,
      MachineTypes.ROUND_GRINDING,
      MachineTypes.PLASTIC_INJECTION,
      MachineTypes.PLASTIC_INJECTION_VERTICAL,
    ]
  },
  {
    id: 'barsupplier',
    icon: <AccountTreeIcon />,
    label: 'Alimentador de Barra',
    present: [MachineTypes.TORNO, MachineTypes.SWISS_LATHE],
  },
  {
    id: 'turreta',
    icon: <GpsFixedIcon />,
    label: 'A.T.C.',
    present: [
      MachineTypes.T3X,
      MachineTypes.T5X,
      MachineTypes.HORIZONTAL,
      MachineTypes.TORNO,
      MachineTypes.TORNOMULTITASKS,
      MachineTypes.BORING,
      MachineTypes.EDM,
      MachineTypes.SWISS_LATHE,
    ]
  },
  {
    id: 'erosionhead',
    icon: <NearMeIcon />,
    label: 'Cabeça de Erosão',
    present: [
      MachineTypes.EDM,
      MachineTypes.EDM_WIRE,
      MachineTypes.EDM_HOLE,
      MachineTypes.BANDSAW,
    ]
  },
  {
    id: 'generator',
    icon: <EvStationIcon />,
    label: 'Gerador',
    present: [
      MachineTypes.EDM,
      MachineTypes.EDM_WIRE,
      MachineTypes.EDM_HOLE,
    ]
  },
  {
    id: 'injectionunit',
    icon: <CreateIcon />,
    label: 'Unidade de Injecção',
    present: [MachineTypes.PLASTIC_INJECTION, MachineTypes.PLASTIC_INJECTION_VERTICAL, MachineTypes.TRY_OUT_PRESS]
  },
  {
    id: 'details',
    icon: <DetailsIcon />,
    label: 'Agregados',
    present: [
      MachineTypes.T3X,
      MachineTypes.T5X,
      MachineTypes.HORIZONTAL,
      MachineTypes.TORNO,
      MachineTypes.TORNOMULTITASKS,
      MachineTypes.BORING,
      MachineTypes.EDM,
      MachineTypes.EDM_WIRE,
      MachineTypes.EDM_HOLE,
      MachineTypes.SWISS_LATHE,
      MachineTypes.FLAT_GRINDING,
      MachineTypes.PLASTIC_INJECTION,
      MachineTypes.PLASTIC_INJECTION_VERTICAL,
    ]
  },
  {
    id: 'logistics',
    icon: <LocalShippingIcon />,
    label: 'Logística',
    present: [
      MachineTypes.T3X,
      MachineTypes.T5X,
      MachineTypes.HORIZONTAL,
      MachineTypes.TORNO,
      MachineTypes.TORNOMULTITASKS,
      MachineTypes.BORING,
      MachineTypes.EDM,
      MachineTypes.EDM_WIRE,
      MachineTypes.EDM_HOLE,
      MachineTypes.SWISS_LATHE,
      MachineTypes.FLAT_GRINDING,
      MachineTypes.ROUND_GRINDING,
      MachineTypes.PLASTIC_INJECTION,
      MachineTypes.PLASTIC_INJECTION_VERTICAL,
      MachineTypes.BANDSAW,
      MachineTypes.TRY_OUT_PRESS,
    ]
  },
  {
    id: 'optionals',
    icon: <AddCircleOutlineIcon />,
    label: 'Opcionais',
    present: [
      MachineTypes.T3X,
      MachineTypes.T5X,
      MachineTypes.HORIZONTAL,
      MachineTypes.TORNO,
      MachineTypes.TORNOMULTITASKS,
      MachineTypes.BORING,
      MachineTypes.EDM,
      MachineTypes.EDM_WIRE,
      MachineTypes.EDM_HOLE,
      MachineTypes.SWISS_LATHE,
      MachineTypes.FLAT_GRINDING,
      MachineTypes.ROUND_GRINDING,
      MachineTypes.PLASTIC_INJECTION,
      MachineTypes.PLASTIC_INJECTION_VERTICAL,
      MachineTypes.BANDSAW,
      MachineTypes.TRY_OUT_PRESS,
    ]
  },
];

async function createFile(file: APIFile): Promise<ExtendedFile> {
  let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/files/machines/${file.filename}`, { responseType: 'blob' });
  let metadata = {
    type: getType(file.originalname) || 'image/png',
    existing: true,
  };

  const f = new File([response.data], file.originalname, metadata) as any;
  f.sysfilename = file.filename;
  f.fullpage = file.fullpage;
  f.id = file.id;

  return f as ExtendedFile;
}

class Form extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { files } = (props.machine || {});
    
    this.state = {
      type: '',
      inited: ((props.machine || {}).type || '').length ? true : false,
      // ...testData,
      tableMeasurement: (props.machine || {}).tableDimensionDiameter ||
        (props.machine || {}).tableDimensionDiameter === 0 ? 'diameter' : 'dimension',
      ...(props.machine || {}),
      options: (props.machine || {}).options || [],
      filesLoading: files ? true : false,
      files: [],
      id: props.isNew ? undefined : (props.machine || {}).id,
    
      submitted: false,
      dirty: false,

      step: 'info',
    };

    if (files) {
      this.loadFiles(files);
    }

    this.changeStep = this.changeStep.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleOptionAdd = this.handleOptionAdd.bind(this);
    this.handleOptionRemove = this.handleOptionRemove.bind(this);
  }

  public componentDidMount() {
    const { fetchSuppliers } = this.props;

    fetchSuppliers();
  }

  public loadFiles(mFiles: APIFile[]) {
    Promise.all((mFiles || [])
      .map((file) => createFile(file))
    ).then((files) => {
      this.setState({ files, filesLoading: false });
    });
  }

  public componentDidUpdate(prevProps: IProps) {
    const { machine, history, redirectReady } = this.props;

    if (JSON.stringify(prevProps.machine) !== JSON.stringify(machine)) {
      const { files } = (machine || {});

      this.setState({
        type: '',
        ...(machine || {}),
        options: (machine || {}).options || [],
        filesLoading: files ? true : false,
        files: [],

        inited: ((machine || {}).type || '').length ? true : false,
        submitted: false,
        dirty: false,
        step: 'info',
        tableMeasurement: (machine || {}).tableDimensionDiameter ||
          (machine || {}).tableDimensionDiameter === 0 ? 'diameter' : 'dimension',
      }, () => {
        if (files) {
          this.loadFiles(files);
        }
      });
    }

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ dirty: false }, () => {
        history.push('/dashboard/machines/list');
      });
    }
  }

  public hasErrors(step?: string) {
    const {
      status,
      supplierId,
      marketplaceVisibility,
      marketplacePriceVisibillity,
      marketplacePriceType
    } = this.state;

    if (
      (step === 'info' || !step) && (
        !status ||
        !supplierId
      )
    ) {
      return true;
    } else if (
      (step === 'optionals' || !step) &&
      marketplaceVisibility &&
      marketplacePriceVisibillity &&
      !marketplacePriceType
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { createMachine, editMachine, machineStatus } = this.props;
    const {
      id,
      type,

      description,
      supplierId,
      customerId,
      brand,
      model,
      serialnumber,
      commandType,
      commandBrand,
      commandModel,
      isMachineNew,
      buyPrice,
      installBuyPrice,
      truckBuyPrice,
      machineYear,
      machineUsageHours,
      status,
      costsOthersMaterial,
      costsOthersTravels,
      costsOthersManHours,
      costsOthersOthers,

      courseX,
      courseY,
      courseZ,
      course4Axis,
      course4AxisMM,
      course5Axis,
      axisType,
      positioning4Axis,
      positioning5Axis,
      autoPalletExchange,
      autoPalletExchangeQuantity,
      autoPalletExchangeDimension,
      tableDimensionX,
      tableDimensionY,
      tableDimensionDiameter,
      spindleTableDistance,
      spindleTableDistanceMin,
      spindleTableDistanceMax,
      spindleTableWeight,
      rotativeTable,
      rotativeTableAngleMinPositioning,
      robot,
      robotNumberOfEletrods,
      robotNumberOfPallets,
      robotNumberOfAxis,
      robotReach,
      tankDimensionLength,
      tankDimensionWidth,
      tankDimensionHeight,
      tankSubmersible,
      tankSubmersibleAuto,
      portableSteeringWheel,
      partMaxHeight,
      dielectricQuantity,
      hasCorePullers,
      corePullersSideMobile,
      corePullersSideFixed,
      corePullersQuantity,
      hasPneumaticValves,
      pneumaticValvesSideMobile,
      pneumaticValvesSideFixed,
      pneumaticValvesQuantity,
      hasControlValveGate,
      controlValveGateQuantity,
      hasMoldTemperatureControl,
      moldTemperatureControlZones,
      maxHydricPression,
      heatingPower,
      oilTankCapacity,
      motorPump,
      motorPower,
      tempControlZones,
      magneticTableDimensionLength,
      magneticTableDimensionWidth,
      magneticTableWeight,
      rapidAxisVelocityX,
      rapidAxisVelocityY,
      rapidAxisVelocityZ,
      programmableVelocityAxisX,
      programmableVelocityAxisY,
      programmableVelocityAxisZ,
      rapidAxisVelocityC,
      programmableVelocityAxisC,
      rapidAxisVelocityB,
      programmableVelocityAxisB,
      transmissionMotor4Axis,
      transmissionMotor5Axis,
      cutMaxVelocity,
      cutMaxAngle,
      cutMaxAngleByLength,
      turningDiameter,
      turningLength,
      detailsRPM,
      detailsPotence,
      detailsTorque,
      detailsConeType,
      headtype,
      guidesWidth,
      RPMAxisC,
      maximumFeedrate,
      connectionPower,
    
      tornTurret,
      toolQuantity,
      warehouseType,
      tollExchangeTime,
      supportType,
      staticSupportNumber,
      motorTool,
      motorToolRPM,
      motorToolPotence,
      motorToolSupports,
      motorToolNew,
      motorToolNewSupportName,
      motorToolNewSupportDescription,
      motorToolNewSupportBuyPrice,
      motorToolNewSupportSellPrice,
      motorToolRadQuantity,
      motorToolAxiQuantity,
      electrodeWarehouse,
      electrodeWarehouseQuantity,
      electrodeMaxHeight,
      electrodeMaxErosionHead,

      highPressureInteriorRefrigeration,
      highPressureInteriorRefrigerationBar,
      wasteExtractor,
      wasteExtractorType,
      toolMeasurer,
      toolMeasurerType,
      autoCenter,
      autoCenterBrand,
      cabinada,
      partCatcher,
      toolMeasurerArm,
      toolMeasurerArmAutomatic,
      steeringWheelElec,
      tornoDiameterTurnable,
      tornoDiameterBush,
      tornoDiameterCar,
      tornoDiameterBarPass,
      spindleRPM,
      spindleMotorPotence,
      spindleDiameter,
      spindleNoseType,
      spindleLiveTools,
      spindleLiveToolsQuantity,
      spindleLiveToolsRPM,
      torque,
      coneType,
      spindleType,
      spindleElectro,
      spindleElectroBrand,
      spindleElectroCoolerType,
      spindleInterfaceType,
      subSpindle,
      subSpindleDiameterTornable,
      subSpindleDiameterBush,
      subSpindleDiameterCar,
      subSpindleDiameterBar,
      subSpindleNoseType,
      subSpindleLiveTools,
      subSpindleLiveToolsQuantity,
      subSpindleLiveToolsRPM,
      subSpindleRPM,
      subSpindleMoterPower,
      subSpindleInterfaceType,
      subSpindlePartCatcher,
      subSpindleTravelAxisLength,
      contraPonto,
      contraPontoType,
      contraPontoPath,
      contraPontoProgrammablePositioning,
      grindDimensionX,
      grindDimensionY,
      grindDimensionZ,
      grindTableDistance,
      grindMinVerticalAdd,
      grindRotation,
      gridPotence,
      gridRPM,
      gridMotorPotence,
      gridRectifierAuto,
      gridBalanceSystem,
      gridFilteringSystem,
      gridFilteringSystemVacuum,
      gridFilteringSystemPaper,
      gridFilteringSystemMagnetic,
      gridFilteringSystemOther,
      gridFilteringSystemOtherValue,
      gridRectifierMaxDiameter,
      gridCentersHeight,
      gridCentersInternalRetifierDiameterMin,
      gridCentersInternalRetifierDiameterMax,
      gridRetifierAngleMin,
      gridRetifierAngleMax,
      gridMaxPartWeight,
      gridCarCourse,
      gridAutoPassing,
      gridCenterHeadType,
      gridCenterContraPontoType,
      gridCenterContraPontoPath,
      gridBalancerIncluded,
      closeUnitPlateDimensionH,
      closeUnitPlateDimensionV,
      closeUnitColumnDistanceH,
      closeUnitColumnDistanceV,
      closeUnitMoldTicknessMin,
      closeUnitMoldTicknessMax,
      closeUnitMinMoldDimension,
      closeUnitForce,
      openUnitForce,
      closeUnitSupPlateCourse,
      closeUnitSupPlateMove,
      closeUnitMobilePlateCourse,
      closeUnitMaxDistanceBetween,
      closeUnitExtractorQuantity,
      closeUnitExtractorForce,
      closeUnitExtractionCourse,

      barSupplierBrand,
      barSupplierModel,
      barSupplierMaxDiameter,
      barSupplierMaxHeight,
      barSupplierDimentionC,
      barSupplierDimentionL,
      barSupplierDimentionA,
      barSupplierWeight,
    
      erosionHeadAxisC360,
      erosionHeadType,
      erosionHeadSubType,
      erosionThreadMin,
      erosionThreadMax,
      erosionThreadingAuto,
      erosionRodMin,
      erosionRodMax,
      erosionRodAuto,
      sawType,
      sawVelocityVariation,
      sawVelocityVariationIMin,
      sawVelocityVariationIMax,
      sawAutoSupply,
      sawCutDimensionsMinDiameter,
      sawCutDimensionsMinRectangle,
      sawCutDimensionsMaxDiameter,
      sawCutDimensionsMaxRectangle,
      sawCutMinLength,
      sawCutMaxLength,
      sawAngleCut,
      sawAngleCutMin,
      sawAngleCutMax,
      sawTapeDimensionsC,
      sawTapeDimensionsL,
      sawTapeDimensionsA,
      sawDimensions,
      sawWeight,
    
      generatorHasRefrigerator,
      generatorAMP,

      injectionUnitFuseDiameter,
      injectionUnitMaxRPMFuse,
      injectionUnitVolume,
      injectionUnitMaxWeight,
      injectionUnitPression,
      injectionUnitRatio,
      injectionCenterRingDiameter,
      injectionScrewStrock,
      injectionRate,
      injectionNozzleContactForce,
      injectionNozzleStroke,
      injectionResineSystem,
      ndInjectionUnit,
      ndInjectionUnitFuseDiameter,
      ndInjectionUnitMaxRPMFuse,
      ndInjectionUnitVolume,
      ndInjectionUnitMaxWeight,
      ndInjectionUnitPression,
      ndInjectionUnitRatio,
      ndInjectionCenterRingDiameter,
      ndInjectionScrewStrock,
      ndInjectionRate,
      ndInjectionNozzleContactForce,
      ndInjectionNozzleStroke,
      ndInjectionResineSystem,
      pressDimensionLength,
      pressDimensionWidth,
      pressDimensionHeight,
      pressWeight,
    
      dimensionC,
      dimensionL,
      dimensionA,
      weight,
      deliveryDate,
      warranty,
      transportPrice,
      paymentConditions,
      commands,
    
      observations,
      youtubeLink,
      catalog,
      catalogOriginalname,
      catalogFilename,
    
      files,

      marketplaceVisibility,
      marketplaceEmphasis,
      marketplacePriceVisibillity,
      marketplacePriceType,

      options,
    } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      editMachine({
        id,
        type,
  
        description,
        supplierId,
        customerId,
        brand,
        model,
        serialnumber,
        commandType,
        commandBrand,
        commandModel,
        isMachineNew,
        buyPrice,
        installBuyPrice,
        truckBuyPrice,
        machineYear,
        machineUsageHours,
        status: status || 0,
        costsOthersMaterial,
        costsOthersTravels,
        costsOthersManHours,
        costsOthersOthers,

        courseX,
        courseY,
        courseZ,
        course4Axis,
        course4AxisMM,
        course5Axis,
        axisType,
        positioning4Axis,
        positioning5Axis,
        autoPalletExchange,
        autoPalletExchangeQuantity,
        autoPalletExchangeDimension,
        tableDimensionX,
        tableDimensionY,
        tableDimensionDiameter,
        spindleTableDistance,
        spindleTableDistanceMin,
        spindleTableDistanceMax,
        spindleTableWeight,
        rotativeTable,
        rotativeTableAngleMinPositioning,
        robot,
        robotNumberOfEletrods,
        robotNumberOfPallets,
        robotNumberOfAxis,
        robotReach,
        tankDimensionLength,
        tankDimensionWidth,
        tankDimensionHeight,
        tankSubmersible,
        tankSubmersibleAuto,
        portableSteeringWheel,
        partMaxHeight,
        dielectricQuantity,
        hasCorePullers,
        corePullersSideMobile,
        corePullersSideFixed,
        corePullersQuantity,
        hasPneumaticValves,
        pneumaticValvesSideMobile,
        pneumaticValvesSideFixed,
        pneumaticValvesQuantity,
        hasControlValveGate,
        controlValveGateQuantity,
        hasMoldTemperatureControl,
        moldTemperatureControlZones,
        maxHydricPression,
        heatingPower,
        oilTankCapacity,
        motorPump,
        motorPower,
        tempControlZones,
        magneticTableDimensionLength,
        magneticTableDimensionWidth,
        magneticTableWeight,
        rapidAxisVelocityX,
        rapidAxisVelocityY,
        rapidAxisVelocityZ,
        programmableVelocityAxisX,
        programmableVelocityAxisY,
        programmableVelocityAxisZ,
        rapidAxisVelocityC,
        programmableVelocityAxisC,
        rapidAxisVelocityB,
        programmableVelocityAxisB,
        transmissionMotor4Axis,
        transmissionMotor5Axis,
        cutMaxVelocity,
        cutMaxAngle,
        cutMaxAngleByLength,
        turningDiameter,
        turningLength,
        detailsRPM,
        detailsPotence,
        detailsTorque,
        detailsConeType,
        headtype,
        guidesWidth,
        RPMAxisC,
        maximumFeedrate,
        connectionPower,
      
        tornTurret,
        toolQuantity,
        warehouseType,
        tollExchangeTime,
        supportType,
        staticSupportNumber,
        motorTool,
        motorToolRPM,
        motorToolPotence,
        motorToolSupports,
        motorToolNew,
        motorToolNewSupportName,
        motorToolNewSupportDescription,
        motorToolNewSupportBuyPrice,
        motorToolNewSupportSellPrice,
        motorToolRadQuantity,
        motorToolAxiQuantity,
        electrodeWarehouse,
        electrodeWarehouseQuantity,
        electrodeMaxHeight,
        electrodeMaxErosionHead,
  
        highPressureInteriorRefrigeration,
        highPressureInteriorRefrigerationBar,
        wasteExtractor,
        wasteExtractorType,
        toolMeasurer,
        toolMeasurerType,
        autoCenter,
        autoCenterBrand,
        cabinada,
        partCatcher,
        toolMeasurerArm,
        toolMeasurerArmAutomatic,
        steeringWheelElec,
        tornoDiameterTurnable,
        tornoDiameterBush,
        tornoDiameterCar,
        tornoDiameterBarPass,
        spindleRPM,
        spindleMotorPotence,
        spindleDiameter,
        spindleNoseType,
        spindleLiveTools,
        spindleLiveToolsQuantity,
        spindleLiveToolsRPM,
        torque,
        coneType,
        spindleType,
        spindleElectro,
        spindleElectroBrand,
        spindleElectroCoolerType,
        spindleInterfaceType,
        subSpindle,
        subSpindleDiameterTornable,
        subSpindleDiameterBush,
        subSpindleDiameterCar,
        subSpindleDiameterBar,
        subSpindleNoseType,
        subSpindleLiveTools,
        subSpindleLiveToolsQuantity,
        subSpindleLiveToolsRPM,
        subSpindleRPM,
        subSpindleMoterPower,
        subSpindleInterfaceType,
        subSpindlePartCatcher,
        subSpindleTravelAxisLength,
        contraPonto,
        contraPontoType,
        contraPontoPath,
        contraPontoProgrammablePositioning,
        grindDimensionX,
        grindDimensionY,
        grindDimensionZ,
        grindTableDistance,
        grindMinVerticalAdd,
        grindRotation,
        gridPotence,
        gridRPM,
        gridMotorPotence,
        gridRectifierAuto,
        gridBalanceSystem,
        gridFilteringSystem,
        gridFilteringSystemVacuum,
        gridFilteringSystemPaper,
        gridFilteringSystemMagnetic,
        gridFilteringSystemOther,
        gridFilteringSystemOtherValue,
        gridRectifierMaxDiameter,
        gridCentersHeight,
        gridCentersInternalRetifierDiameterMin,
        gridCentersInternalRetifierDiameterMax,
        gridRetifierAngleMin,
        gridRetifierAngleMax,
        gridMaxPartWeight,
        gridCarCourse,
        gridAutoPassing,
        gridCenterHeadType,
        gridCenterContraPontoType,
        gridCenterContraPontoPath,
        gridBalancerIncluded,
        closeUnitPlateDimensionH,
        closeUnitPlateDimensionV,
        closeUnitColumnDistanceH,
        closeUnitColumnDistanceV,
        closeUnitMoldTicknessMin,
        closeUnitMoldTicknessMax,
        closeUnitMinMoldDimension,
        closeUnitForce,
        openUnitForce,
        closeUnitSupPlateCourse,
        closeUnitSupPlateMove,
        closeUnitMobilePlateCourse,
        closeUnitMaxDistanceBetween,
        closeUnitExtractorQuantity,
        closeUnitExtractorForce,
        closeUnitExtractionCourse,

        barSupplierBrand,
        barSupplierModel,
        barSupplierMaxDiameter,
        barSupplierMaxHeight,
        barSupplierDimentionC,
        barSupplierDimentionL,
        barSupplierDimentionA,
        barSupplierWeight,
          
        erosionHeadAxisC360,
        erosionHeadType,
        erosionHeadSubType,
        erosionThreadMin,
        erosionThreadMax,
        erosionThreadingAuto,
        erosionRodMin,
        erosionRodMax,
        erosionRodAuto,
        sawType,
        sawVelocityVariation,
        sawVelocityVariationIMin,
        sawVelocityVariationIMax,
        sawAutoSupply,
        sawCutDimensionsMinDiameter,
        sawCutDimensionsMinRectangle,
        sawCutDimensionsMaxDiameter,
        sawCutDimensionsMaxRectangle,
        sawCutMinLength,
        sawCutMaxLength,
        sawAngleCut,
        sawAngleCutMin,
        sawAngleCutMax,
        sawTapeDimensionsC,
        sawTapeDimensionsL,
        sawTapeDimensionsA,
        sawDimensions,
        sawWeight,
  
        generatorHasRefrigerator,
        generatorAMP,

        injectionUnitFuseDiameter,
        injectionUnitMaxRPMFuse,
        injectionUnitVolume,
        injectionUnitMaxWeight,
        injectionUnitPression,
        injectionUnitRatio,
        injectionCenterRingDiameter,
        injectionScrewStrock,
        injectionRate,
        injectionNozzleContactForce,
        injectionNozzleStroke,
        injectionResineSystem,
        ndInjectionUnit,
        ndInjectionUnitFuseDiameter,
        ndInjectionUnitMaxRPMFuse,
        ndInjectionUnitVolume,
        ndInjectionUnitMaxWeight,
        ndInjectionUnitPression,
        ndInjectionUnitRatio,
        ndInjectionCenterRingDiameter,
        ndInjectionScrewStrock,
        ndInjectionRate,
        ndInjectionNozzleContactForce,
        ndInjectionNozzleStroke,
        ndInjectionResineSystem,
        pressDimensionLength,
        pressDimensionWidth,
        pressDimensionHeight,
        pressWeight,
        
        dimensionC,
        dimensionL,
        dimensionA,
        weight,
        deliveryDate,
        warranty,
        transportPrice,
        paymentConditions,
        commands,
      
        observations,
        youtubeLink,
        catalog,
        catalogOriginalname,
        catalogFilename,
  
        files,

        marketplaceVisibility: machineStatus.find((s) => s.id === Number(status))?.value === 'Vendida' ? false : marketplaceVisibility,
        marketplaceEmphasis,
        marketplacePriceVisibillity,
        marketplacePriceType,

        options: (options || []).filter((o) => !!o.description || !!o.purchaseValue || !!o.sellValue).map((o) => ({
          description: o.description || undefined,
          purchaseValue: o.purchaseValue || undefined,
          sellValue: o.sellValue || undefined,
        })),
      });
    } else {
      createMachine({
        type,
  
        description,
        supplierId,
        customerId,
        brand,
        model,
        serialnumber,
        commandType,
        commandBrand,
        commandModel,
        isMachineNew,
        buyPrice,
        installBuyPrice,
        truckBuyPrice,
        machineYear,
        machineUsageHours,
        status: status || 0,
        costsOthersMaterial,
        costsOthersTravels,
        costsOthersManHours,
        costsOthersOthers,

        courseX,
        courseY,
        courseZ,
        course4Axis,
        course4AxisMM,
        course5Axis,
        axisType,
        positioning4Axis,
        positioning5Axis,
        autoPalletExchange,
        autoPalletExchangeQuantity,
        autoPalletExchangeDimension,
        tableDimensionX,
        tableDimensionY,
        tableDimensionDiameter,
        spindleTableDistance,
        spindleTableDistanceMin,
        spindleTableDistanceMax,
        spindleTableWeight,
        rotativeTable,
        rotativeTableAngleMinPositioning,
        robot,
        robotNumberOfEletrods,
        robotNumberOfPallets,
        robotNumberOfAxis,
        robotReach,
        tankDimensionLength,
        tankDimensionWidth,
        tankDimensionHeight,
        tankSubmersible,
        tankSubmersibleAuto,
        portableSteeringWheel,
        partMaxHeight,
        dielectricQuantity,
        hasCorePullers,
        corePullersSideMobile,
        corePullersSideFixed,
        corePullersQuantity,
        hasPneumaticValves,
        pneumaticValvesSideMobile,
        pneumaticValvesSideFixed,
        pneumaticValvesQuantity,
        hasControlValveGate,
        controlValveGateQuantity,
        hasMoldTemperatureControl,
        moldTemperatureControlZones,
        maxHydricPression,
        heatingPower,
        oilTankCapacity,
        motorPump,
        motorPower,
        tempControlZones,
        magneticTableDimensionLength,
        magneticTableDimensionWidth,
        magneticTableWeight,
        rapidAxisVelocityX,
        rapidAxisVelocityY,
        rapidAxisVelocityZ,
        programmableVelocityAxisX,
        programmableVelocityAxisY,
        programmableVelocityAxisZ,
        rapidAxisVelocityC,
        programmableVelocityAxisC,
        rapidAxisVelocityB,
        programmableVelocityAxisB,
        transmissionMotor4Axis,
        transmissionMotor5Axis,
        cutMaxVelocity,
        cutMaxAngle,
        cutMaxAngleByLength,
        turningDiameter,
        turningLength,
        detailsRPM,
        detailsPotence,
        detailsTorque,
        detailsConeType,
        headtype,
        guidesWidth,
        RPMAxisC,
        maximumFeedrate,
        connectionPower,
      
        tornTurret,
        toolQuantity,
        warehouseType,
        tollExchangeTime,
        supportType,
        staticSupportNumber,
        motorTool,
        motorToolRPM,
        motorToolPotence,
        motorToolSupports,
        motorToolNew,
        motorToolNewSupportName,
        motorToolNewSupportDescription,
        motorToolNewSupportBuyPrice,
        motorToolNewSupportSellPrice,
        motorToolRadQuantity,
        motorToolAxiQuantity,
        electrodeWarehouse,
        electrodeWarehouseQuantity,
        electrodeMaxHeight,
        electrodeMaxErosionHead,
  
        highPressureInteriorRefrigeration,
        highPressureInteriorRefrigerationBar,
        wasteExtractor,
        wasteExtractorType,
        toolMeasurer,
        toolMeasurerType,
        autoCenter,
        autoCenterBrand,
        cabinada,
        partCatcher,
        toolMeasurerArm,
        toolMeasurerArmAutomatic,
        steeringWheelElec,
        tornoDiameterTurnable,
        tornoDiameterBush,
        tornoDiameterCar,
        tornoDiameterBarPass,
        spindleRPM,
        spindleMotorPotence,
        spindleDiameter,
        spindleNoseType,
        spindleLiveTools,
        spindleLiveToolsQuantity,
        spindleLiveToolsRPM,
        torque,
        coneType,
        spindleType,
        spindleElectro,
        spindleElectroBrand,
        spindleElectroCoolerType,
        spindleInterfaceType,
        subSpindle,
        subSpindleDiameterTornable,
        subSpindleDiameterBush,
        subSpindleDiameterCar,
        subSpindleDiameterBar,
        subSpindleNoseType,
        subSpindleLiveTools,
        subSpindleLiveToolsQuantity,
        subSpindleLiveToolsRPM,
        subSpindleRPM,
        subSpindleMoterPower,
        subSpindleInterfaceType,
        subSpindlePartCatcher,
        subSpindleTravelAxisLength,
        contraPonto,
        contraPontoType,
        contraPontoPath,
        contraPontoProgrammablePositioning,
        grindDimensionX,
        grindDimensionY,
        grindDimensionZ,
        grindTableDistance,
        grindMinVerticalAdd,
        grindRotation,
        gridPotence,
        gridRPM,
        gridMotorPotence,
        gridRectifierAuto,
        gridBalanceSystem,
        gridFilteringSystem,
        gridFilteringSystemVacuum,
        gridFilteringSystemPaper,
        gridFilteringSystemMagnetic,
        gridFilteringSystemOther,
        gridFilteringSystemOtherValue,
        gridRectifierMaxDiameter,
        gridCentersHeight,
        gridCentersInternalRetifierDiameterMin,
        gridCentersInternalRetifierDiameterMax,
        gridRetifierAngleMin,
        gridRetifierAngleMax,
        gridMaxPartWeight,
        gridCarCourse,
        gridAutoPassing,
        gridCenterHeadType,
        gridCenterContraPontoType,
        gridCenterContraPontoPath,
        gridBalancerIncluded,
        closeUnitPlateDimensionH,
        closeUnitPlateDimensionV,
        closeUnitColumnDistanceH,
        closeUnitColumnDistanceV,
        closeUnitMoldTicknessMin,
        closeUnitMoldTicknessMax,
        closeUnitMinMoldDimension,
        closeUnitForce,
        openUnitForce,
        closeUnitSupPlateCourse,
        closeUnitSupPlateMove,
        closeUnitMobilePlateCourse,
        closeUnitMaxDistanceBetween,
        closeUnitExtractorQuantity,
        closeUnitExtractorForce,
        closeUnitExtractionCourse,

        barSupplierBrand,
        barSupplierModel,
        barSupplierMaxDiameter,
        barSupplierMaxHeight,
        barSupplierDimentionC,
        barSupplierDimentionL,
        barSupplierDimentionA,
        barSupplierWeight,
          
        erosionHeadAxisC360,
        erosionHeadType,
        erosionHeadSubType,
        erosionThreadMin,
        erosionThreadMax,
        erosionThreadingAuto,
        erosionRodMin,
        erosionRodMax,
        erosionRodAuto,
        sawType,
        sawVelocityVariation,
        sawVelocityVariationIMin,
        sawVelocityVariationIMax,
        sawAutoSupply,
        sawCutDimensionsMinDiameter,
        sawCutDimensionsMinRectangle,
        sawCutDimensionsMaxDiameter,
        sawCutDimensionsMaxRectangle,
        sawCutMinLength,
        sawCutMaxLength,
        sawAngleCut,
        sawAngleCutMin,
        sawAngleCutMax,
        sawTapeDimensionsC,
        sawTapeDimensionsL,
        sawTapeDimensionsA,
        sawDimensions,
        sawWeight,
  
        generatorHasRefrigerator,
        generatorAMP,

        injectionUnitFuseDiameter,
        injectionUnitMaxRPMFuse,
        injectionUnitVolume,
        injectionUnitMaxWeight,
        injectionUnitPression,
        injectionUnitRatio,
        injectionCenterRingDiameter,
        injectionScrewStrock,
        injectionRate,
        injectionNozzleContactForce,
        injectionNozzleStroke,
        injectionResineSystem,
        ndInjectionUnit,
        ndInjectionUnitFuseDiameter,
        ndInjectionUnitMaxRPMFuse,
        ndInjectionUnitVolume,
        ndInjectionUnitMaxWeight,
        ndInjectionUnitPression,
        ndInjectionUnitRatio,
        ndInjectionCenterRingDiameter,
        ndInjectionScrewStrock,
        ndInjectionRate,
        ndInjectionNozzleContactForce,
        ndInjectionNozzleStroke,
        ndInjectionResineSystem,
        pressDimensionLength,
        pressDimensionWidth,
        pressDimensionHeight,
        pressWeight,
    
        dimensionC,
        dimensionL,
        dimensionA,
        weight,
        deliveryDate,
        warranty,
        transportPrice,
        paymentConditions,
        commands,
      
        observations,
        youtubeLink,
        catalog,
      
        files,

        marketplaceVisibility: machineStatus.find((s) => s.id === Number(status))?.value === 'Vendida' ? false : marketplaceVisibility,
        marketplaceEmphasis,
        marketplacePriceVisibillity,
        marketplacePriceType,

        options: (options || []).filter((o) => !!o.description || !!o.purchaseValue || !!o.sellValue).map((o) => ({
          description: o.description || undefined,
          purchaseValue: o.purchaseValue || undefined,
          sellValue: o.sellValue || undefined,
        })),
      });
    }
  }

  public handleFileChange(field: string, value: any, index: number) {
    const { files } = this.state;

    files[index][field as 'fullpage'] = value;

    this.setState({ files, dirty: true });
  }

  public handleChange(field: string, value: any, triggerUpdate = true) {
    const newState = { [field]: value !== '' ? value : undefined, dirty: true };

    if (field === 'commandType') {
      newState.commandBrand = undefined;
      newState.commandModel = undefined;
    }
    
    this.setState(newState as Pick<IState, 'dirty'>, () => {
      const { machines } = this.props;

      if (field === 'type') {
        const sampleMachine = machines.sort((a, b) => a.id > b.id ? -1 : 1).find((m) => m.type === value);

        if (sampleMachine) {
          const {
            description,
            supplierId,
            customerId,
            brand,
            model,
            serialnumber,
            commandType,
            commandBrand,
            commandModel,
            isMachineNew,
            buyPrice,
            installBuyPrice,
            truckBuyPrice,
            machineYear,
            machineUsageHours,
            status,
            costsOthersMaterial,
            costsOthersTravels,
            costsOthersManHours,
            costsOthersOthers,
      
            courseX,
            courseY,
            courseZ,
            course4Axis,
            course4AxisMM,
            course5Axis,
            axisType,
            positioning4Axis,
            positioning5Axis,
            autoPalletExchange,
            autoPalletExchangeQuantity,
            autoPalletExchangeDimension,
            tableDimensionX,
            tableDimensionY,
            tableDimensionDiameter,
            spindleTableDistance,
            spindleTableDistanceMin,
            spindleTableDistanceMax,      
            spindleTableWeight,
            rotativeTable,
            rotativeTableAngleMinPositioning,
            robot,
            robotNumberOfEletrods,
            robotNumberOfPallets,
            robotNumberOfAxis,
            robotReach,
            tankDimensionLength,
            tankDimensionWidth,
            tankDimensionHeight,
            tankSubmersible,
            tankSubmersibleAuto,
            portableSteeringWheel,
            partMaxHeight,
            dielectricQuantity,
            hasCorePullers,
            corePullersSideMobile,
            corePullersSideFixed,
            corePullersQuantity,
            hasPneumaticValves,
            pneumaticValvesSideMobile,
            pneumaticValvesSideFixed,
            pneumaticValvesQuantity,
            hasControlValveGate,
            controlValveGateQuantity,
            hasMoldTemperatureControl,
            moldTemperatureControlZones,
            maxHydricPression,
            heatingPower,
            oilTankCapacity,
            motorPump,
            motorPower,
            tempControlZones,
            magneticTableDimensionLength,
            magneticTableDimensionWidth,
            magneticTableWeight,
            rapidAxisVelocityX,
            rapidAxisVelocityY,
            rapidAxisVelocityZ,
            programmableVelocityAxisX,
            programmableVelocityAxisY,
            programmableVelocityAxisZ,
            rapidAxisVelocityC,
            programmableVelocityAxisC,
            rapidAxisVelocityB,
            programmableVelocityAxisB,
            transmissionMotor4Axis,
            transmissionMotor5Axis,
            cutMaxVelocity,
            cutMaxAngle,
            cutMaxAngleByLength,
            turningDiameter,
            turningLength,
            detailsRPM,
            detailsPotence,
            detailsTorque,
            detailsConeType,
            headtype,
            guidesWidth,
            RPMAxisC,
            maximumFeedrate,
            connectionPower,
          
            tornTurret,
            toolQuantity,
            warehouseType,
            tollExchangeTime,
            supportType,
            staticSupportNumber,
            motorTool,
            motorToolRPM,
            motorToolPotence,
            motorToolSupports,
            motorToolNew,
            motorToolNewSupportName,
            motorToolNewSupportDescription,
            motorToolNewSupportBuyPrice,
            motorToolNewSupportSellPrice,
            motorToolRadQuantity,
            motorToolAxiQuantity,
            electrodeWarehouse,
            electrodeWarehouseQuantity,
            electrodeMaxHeight,
            electrodeMaxErosionHead,
      
            highPressureInteriorRefrigeration,
            highPressureInteriorRefrigerationBar,
            wasteExtractor,
            wasteExtractorType,
            toolMeasurer,
            toolMeasurerType,
            autoCenter,
            autoCenterBrand,
            cabinada,
            partCatcher,
            toolMeasurerArm,
            toolMeasurerArmAutomatic,
            steeringWheelElec,
            tornoDiameterTurnable,
            tornoDiameterBush,
            tornoDiameterCar,
            tornoDiameterBarPass,
            spindleRPM,
            spindleMotorPotence,
            spindleDiameter,
            spindleNoseType,
            spindleLiveTools,
            spindleLiveToolsQuantity,
            spindleLiveToolsRPM,
            torque,
            coneType,
            spindleType,
            spindleElectro,
            spindleElectroBrand,
            spindleElectroCoolerType,
            spindleInterfaceType,
            subSpindle,
            subSpindleDiameterTornable,
            subSpindleDiameterBush,
            subSpindleDiameterCar,
            subSpindleDiameterBar,
            subSpindleNoseType,
            subSpindleLiveTools,
            subSpindleLiveToolsQuantity,
            subSpindleLiveToolsRPM,
            subSpindleRPM,
            subSpindleMoterPower,
            subSpindleInterfaceType,
            subSpindlePartCatcher,
            subSpindleTravelAxisLength,
            contraPonto,
            contraPontoType,
            contraPontoPath,
            contraPontoProgrammablePositioning,
            grindDimensionX,
            grindDimensionY,
            grindDimensionZ,
            grindTableDistance,
            grindMinVerticalAdd,
            grindRotation,
            gridPotence,
            gridRPM,
            gridMotorPotence,
            gridRectifierAuto,
            gridBalanceSystem,
            gridFilteringSystem,
            gridFilteringSystemVacuum,
            gridFilteringSystemPaper,
            gridFilteringSystemMagnetic,
            gridFilteringSystemOther,
            gridFilteringSystemOtherValue,
            gridRectifierMaxDiameter,
            gridCentersHeight,
            gridCentersInternalRetifierDiameterMin,
            gridCentersInternalRetifierDiameterMax,
            gridRetifierAngleMin,
            gridRetifierAngleMax,
            gridMaxPartWeight,
            gridCarCourse,
            gridAutoPassing,
            gridCenterHeadType,
            gridCenterContraPontoType,
            gridCenterContraPontoPath,
            gridBalancerIncluded,
            closeUnitPlateDimensionH,
            closeUnitPlateDimensionV,
            closeUnitColumnDistanceH,
            closeUnitColumnDistanceV,
            closeUnitMoldTicknessMin,
            closeUnitMoldTicknessMax,
            closeUnitMinMoldDimension,
            closeUnitForce,
            openUnitForce,
            closeUnitSupPlateCourse,
            closeUnitSupPlateMove,
            closeUnitMobilePlateCourse,
            closeUnitMaxDistanceBetween,
            closeUnitExtractorQuantity,
            closeUnitExtractorForce,
            closeUnitExtractionCourse,

            barSupplierBrand,
            barSupplierModel,
            barSupplierMaxDiameter,
            barSupplierMaxHeight,
            barSupplierDimentionC,
            barSupplierDimentionL,
            barSupplierDimentionA,
            barSupplierWeight,

            erosionHeadAxisC360,
            erosionHeadType,
            erosionHeadSubType,
            erosionThreadMin,
            erosionThreadMax,
            erosionThreadingAuto,
            erosionRodMin,
            erosionRodMax,
            erosionRodAuto,
            sawType,
            sawVelocityVariation,
            sawVelocityVariationIMin,
            sawVelocityVariationIMax,
            sawAutoSupply,
            sawCutDimensionsMinDiameter,
            sawCutDimensionsMinRectangle,
            sawCutDimensionsMaxDiameter,
            sawCutDimensionsMaxRectangle,
            sawCutMinLength,
            sawCutMaxLength,
            sawAngleCut,
            sawAngleCutMin,
            sawAngleCutMax,
            sawTapeDimensionsC,
            sawTapeDimensionsL,
            sawTapeDimensionsA,
            sawDimensions,
            sawWeight,
      
            generatorHasRefrigerator,
            generatorAMP,

            injectionUnitFuseDiameter,
            injectionUnitMaxRPMFuse,
            injectionUnitVolume,
            injectionUnitMaxWeight,
            injectionUnitPression,
            injectionUnitRatio,
            injectionCenterRingDiameter,
            injectionScrewStrock,
            injectionRate,
            injectionNozzleContactForce,
            injectionNozzleStroke,
            injectionResineSystem,
            ndInjectionUnit,
            ndInjectionUnitFuseDiameter,
            ndInjectionUnitMaxRPMFuse,
            ndInjectionUnitVolume,
            ndInjectionUnitMaxWeight,
            ndInjectionUnitPression,
            ndInjectionUnitRatio,
            ndInjectionCenterRingDiameter,
            ndInjectionScrewStrock,
            ndInjectionRate,
            ndInjectionNozzleContactForce,
            ndInjectionNozzleStroke,
            ndInjectionResineSystem,
            pressDimensionLength,
            pressDimensionWidth,
            pressDimensionHeight,
            pressWeight,
        
            dimensionC,
            dimensionL,
            dimensionA,
            weight,
            deliveryDate,
            warranty,
            transportPrice,
            paymentConditions,
            commands,
          
            observations,
            youtubeLink,
    
            marketplaceVisibility,
            marketplaceEmphasis,
            marketplacePriceVisibillity,
            marketplacePriceType,
          } = sampleMachine;

          this.setState({
            description,
            supplierId,
            customerId,
            brand,
            model,
            serialnumber,
            commandType,
            commandBrand,
            commandModel,
            isMachineNew,
            buyPrice,
            installBuyPrice,
            truckBuyPrice,
            machineYear,
            machineUsageHours,
            status,
            costsOthersMaterial,
            costsOthersTravels,
            costsOthersManHours,
            costsOthersOthers,
      
            courseX,
            courseY,
            courseZ,
            course4Axis,
            course4AxisMM,
            course5Axis,
            axisType,
            positioning4Axis,
            positioning5Axis,
            autoPalletExchange,
            autoPalletExchangeQuantity,
            autoPalletExchangeDimension,
            tableDimensionX,
            tableDimensionY,
            tableDimensionDiameter,
            spindleTableDistance,
            spindleTableDistanceMin,
            spindleTableDistanceMax,
            spindleTableWeight,
            rotativeTable,
            rotativeTableAngleMinPositioning,
            robot,
            robotNumberOfEletrods,
            robotNumberOfPallets,
            robotNumberOfAxis,
            robotReach,
            tankDimensionLength,
            tankDimensionWidth,
            tankDimensionHeight,
            tankSubmersible,
            tankSubmersibleAuto,
            portableSteeringWheel,
            partMaxHeight,
            dielectricQuantity,
            hasCorePullers,
            corePullersSideMobile,
            corePullersSideFixed,
            corePullersQuantity,
            hasPneumaticValves,
            pneumaticValvesSideMobile,
            pneumaticValvesSideFixed,
            pneumaticValvesQuantity,
            hasControlValveGate,
            controlValveGateQuantity,
            hasMoldTemperatureControl,
            moldTemperatureControlZones,
            maxHydricPression,
            heatingPower,
            oilTankCapacity,
            motorPump,
            motorPower,
            tempControlZones,
            magneticTableDimensionLength,
            magneticTableDimensionWidth,
            magneticTableWeight,
            rapidAxisVelocityX,
            rapidAxisVelocityY,
            rapidAxisVelocityZ,
            programmableVelocityAxisX,
            programmableVelocityAxisY,
            programmableVelocityAxisZ,
            rapidAxisVelocityC,
            programmableVelocityAxisC,
            rapidAxisVelocityB,
            programmableVelocityAxisB,
            transmissionMotor4Axis,
            transmissionMotor5Axis,
            cutMaxVelocity,
            cutMaxAngle,
            cutMaxAngleByLength,
            turningDiameter,
            turningLength,
            detailsRPM,
            detailsPotence,
            detailsTorque,
            detailsConeType,
            headtype,
            guidesWidth,
            RPMAxisC,
            maximumFeedrate,
            connectionPower,
          
            tornTurret,
            toolQuantity,
            warehouseType,
            tollExchangeTime,
            supportType,
            staticSupportNumber,
            motorTool,
            motorToolRPM,
            motorToolPotence,
            motorToolSupports,
            motorToolNew,
            motorToolNewSupportName,
            motorToolNewSupportDescription,
            motorToolNewSupportBuyPrice,
            motorToolNewSupportSellPrice,
            motorToolRadQuantity,
            motorToolAxiQuantity,
            electrodeWarehouse,
            electrodeWarehouseQuantity,
            electrodeMaxHeight,
            electrodeMaxErosionHead,
      
            highPressureInteriorRefrigeration,
            highPressureInteriorRefrigerationBar,
            wasteExtractor,
            wasteExtractorType,
            toolMeasurer,
            toolMeasurerType,
            autoCenter,
            autoCenterBrand,
            cabinada,
            partCatcher,
            toolMeasurerArm,
            toolMeasurerArmAutomatic,
            steeringWheelElec,
            tornoDiameterTurnable,
            tornoDiameterBush,
            tornoDiameterCar,
            tornoDiameterBarPass,
            spindleRPM,
            spindleMotorPotence,
            spindleDiameter,
            spindleNoseType,
            spindleLiveTools,
            spindleLiveToolsQuantity,
            spindleLiveToolsRPM,
            torque,
            coneType,
            spindleType,
            spindleElectro,
            spindleElectroBrand,
            spindleElectroCoolerType,
            spindleInterfaceType,
            subSpindle,
            subSpindleDiameterTornable,
            subSpindleDiameterBush,
            subSpindleDiameterCar,
            subSpindleDiameterBar,
            subSpindleNoseType,
            subSpindleLiveTools,
            subSpindleLiveToolsQuantity,
            subSpindleLiveToolsRPM,
            subSpindleRPM,
            subSpindleMoterPower,
            subSpindleInterfaceType,
            subSpindlePartCatcher,
            subSpindleTravelAxisLength,
            contraPonto,
            contraPontoType,
            contraPontoPath,
            contraPontoProgrammablePositioning,
            grindDimensionX,
            grindDimensionY,
            grindDimensionZ,
            grindTableDistance,
            grindMinVerticalAdd,
            grindRotation,
            gridPotence,
            gridRPM,
            gridMotorPotence,
            gridRectifierAuto,
            gridBalanceSystem,
            gridFilteringSystem,
            gridFilteringSystemVacuum,
            gridFilteringSystemPaper,
            gridFilteringSystemMagnetic,
            gridFilteringSystemOther,
            gridFilteringSystemOtherValue,
            gridRectifierMaxDiameter,
            gridCentersHeight,
            gridCentersInternalRetifierDiameterMin,
            gridCentersInternalRetifierDiameterMax,
            gridRetifierAngleMin,
            gridRetifierAngleMax,
            gridMaxPartWeight,
            gridCarCourse,
            gridAutoPassing,
            gridCenterHeadType,
            gridCenterContraPontoType,
            gridCenterContraPontoPath,
            gridBalancerIncluded,
            closeUnitPlateDimensionH,
            closeUnitPlateDimensionV,
            closeUnitColumnDistanceH,
            closeUnitColumnDistanceV,
            closeUnitMoldTicknessMin,
            closeUnitMoldTicknessMax,
            closeUnitMinMoldDimension,
            closeUnitForce,
            openUnitForce,
            closeUnitSupPlateCourse,
            closeUnitSupPlateMove,
            closeUnitMobilePlateCourse,
            closeUnitMaxDistanceBetween,
            closeUnitExtractorQuantity,
            closeUnitExtractorForce,
            closeUnitExtractionCourse,

            barSupplierBrand,
            barSupplierModel,
            barSupplierMaxDiameter,
            barSupplierMaxHeight,
            barSupplierDimentionC,
            barSupplierDimentionL,
            barSupplierDimentionA,
            barSupplierWeight,
              
            erosionHeadAxisC360,
            erosionHeadType,
            erosionHeadSubType,
            erosionThreadMin,
            erosionThreadMax,
            erosionThreadingAuto,
            erosionRodMin,
            erosionRodMax,
            erosionRodAuto,
            sawType,
            sawVelocityVariation,
            sawVelocityVariationIMin,
            sawVelocityVariationIMax,
            sawAutoSupply,
            sawCutDimensionsMinDiameter,
            sawCutDimensionsMinRectangle,
            sawCutDimensionsMaxDiameter,
            sawCutDimensionsMaxRectangle,
            sawCutMinLength,
            sawCutMaxLength,
            sawAngleCut,
            sawAngleCutMin,
            sawAngleCutMax,
            sawTapeDimensionsC,
            sawTapeDimensionsL,
            sawTapeDimensionsA,
            sawDimensions,
            sawWeight,
      
            generatorHasRefrigerator,
            generatorAMP,

            injectionUnitFuseDiameter,
            injectionUnitMaxRPMFuse,
            injectionUnitVolume,
            injectionUnitMaxWeight,
            injectionUnitPression,
            injectionUnitRatio,
            injectionCenterRingDiameter,
            injectionScrewStrock,
            injectionRate,
            injectionNozzleContactForce,
            injectionNozzleStroke,
            injectionResineSystem,
            ndInjectionUnit,
            ndInjectionUnitFuseDiameter,
            ndInjectionUnitMaxRPMFuse,
            ndInjectionUnitVolume,
            ndInjectionUnitMaxWeight,
            ndInjectionUnitPression,
            ndInjectionUnitRatio,
            ndInjectionCenterRingDiameter,
            ndInjectionScrewStrock,
            ndInjectionRate,
            ndInjectionNozzleContactForce,
            ndInjectionNozzleStroke,
            ndInjectionResineSystem,
            pressDimensionLength,
            pressDimensionWidth,
            pressDimensionHeight,
            pressWeight,
              
            dimensionC,
            dimensionL,
            dimensionA,
            weight,
            deliveryDate,
            warranty,
            transportPrice,
            paymentConditions,
            commands,
          
            observations,
            youtubeLink,
          
            marketplaceVisibility,
            marketplaceEmphasis,
            marketplacePriceVisibillity,
            marketplacePriceType,
          });
        }
      }
    });
  }

  public handleOptionChange(field: string, value: any, i: number) {
    const { options } = this.state;

    options[i] = { ...(options[i] || {}), [field]: value !== '' ? value : undefined };

    this.setState({ options, dirty: true } as Pick<IState, 'dirty'>);
  }

  public handleOptionAdd() {
    const { options } = this.state;
    options.push({});
    this.setState({ options, dirty: true } as Pick<IState, 'dirty'>);
  }

  public handleOptionRemove(i: number) {
    const { options } = this.state;
    options.splice(i, 1);
    this.setState({ options, dirty: true } as Pick<IState, 'dirty'>);
  }

  public handleFiles(files: any[]) {
    this.setState({ files });
  }

  public changeStep(step: string) {
    this.setState({ step });
  }

  public handlePrev() {
    this.setState((prevState) => {
      const activeSteps = steps.filter((s) => s.present.indexOf(prevState.type as MachineTypes) !== -1);
      const currentStep = activeSteps.findIndex((s) => s.id === prevState.step);

      return { step: activeSteps[currentStep - 1].id };
    });
  }

  public handleNext() {
    const { inited, step, type } = this.state;

    if (!inited) {
      return this.setState({ inited: true });
    }

    if (this.hasErrors(step)) {
      return this.setState({ submitted: true });
    }

    const activeSteps = steps.filter((s) => s.present.indexOf(type as MachineTypes) !== -1);
    const currentStep = activeSteps.findIndex((s) => s.id === step);

    this.setState({ step: activeSteps[currentStep + 1].id });
  }

  public render() {
    const { loading, suppliers } = this.props;
    const {
      id,
      type,
      inited,

      description,
      supplierId,
      brand,
      model,
      serialnumber,
      commandType,
      commandBrand,
      commandModel,
      isMachineNew,
      buyPrice,
      installBuyPrice,
      truckBuyPrice,
      machineYear,
      machineUsageHours,
      status,
      costsOthersMaterial,
      costsOthersTravels,
      costsOthersManHours,
      costsOthersOthers,
  
      courseX,
      courseY,
      courseZ,
      course4Axis,
      course4AxisMM,
      course5Axis,
      axisType,
      positioning4Axis,
      positioning5Axis,
      autoPalletExchange,
      autoPalletExchangeQuantity,
      autoPalletExchangeDimension,
      tableMeasurement,
      tableDimensionX,
      tableDimensionY,
      tableDimensionDiameter,
      spindleTableDistance,
      spindleTableDistanceMin,
      spindleTableDistanceMax,
      spindleTableWeight,
      rotativeTable,
      rotativeTableAngleMinPositioning,
      robot,
      robotNumberOfEletrods,
      robotNumberOfPallets,
      robotNumberOfAxis,
      robotReach,
      tankDimensionLength,
      tankDimensionWidth,
      tankDimensionHeight,
      tankSubmersible,
      tankSubmersibleAuto,
      portableSteeringWheel,
      partMaxHeight,
      dielectricQuantity,
      hasCorePullers,
      corePullersSideMobile,
      corePullersSideFixed,
      corePullersQuantity,
      hasPneumaticValves,
      pneumaticValvesSideMobile,
      pneumaticValvesSideFixed,
      pneumaticValvesQuantity,
      hasControlValveGate,
      controlValveGateQuantity,
      hasMoldTemperatureControl,
      moldTemperatureControlZones,
      maxHydricPression,
      heatingPower,
      oilTankCapacity,
      motorPump,
      motorPower,
      tempControlZones,
      magneticTableDimensionLength,
      magneticTableDimensionWidth,
      magneticTableWeight,
      rapidAxisVelocityX,
      rapidAxisVelocityY,
      rapidAxisVelocityZ,
      programmableVelocityAxisX,
      programmableVelocityAxisY,
      programmableVelocityAxisZ,
      rapidAxisVelocityC,
      programmableVelocityAxisC,
      rapidAxisVelocityB,
      programmableVelocityAxisB,
      transmissionMotor4Axis,
      transmissionMotor5Axis,
      cutMaxVelocity,
      cutMaxAngle,
      cutMaxAngleByLength,
      turningDiameter,
      turningLength,
      detailsRPM,
      detailsPotence,
      detailsTorque,
      detailsConeType,
      headtype,
      guidesWidth,
      RPMAxisC,
      maximumFeedrate,
      connectionPower,
    
      tornTurret,
      toolQuantity,
      warehouseType,
      tollExchangeTime,
      supportType,
      staticSupportNumber,
      motorTool,
      motorToolRPM,
      motorToolPotence,
      motorToolSupports,
      motorToolNew,
      motorToolNewSupportName,
      motorToolNewSupportDescription,
      motorToolNewSupportBuyPrice,
      motorToolNewSupportSellPrice,
      motorToolRadQuantity,
      motorToolAxiQuantity,
      electrodeWarehouse,
      electrodeWarehouseQuantity,
      electrodeMaxHeight,
      electrodeMaxErosionHead,

      highPressureInteriorRefrigeration,
      highPressureInteriorRefrigerationBar,
      wasteExtractor,
      wasteExtractorType,
      toolMeasurer,
      toolMeasurerType,
      autoCenter,
      autoCenterBrand,
      cabinada,
      partCatcher,
      toolMeasurerArm,
      toolMeasurerArmAutomatic,
      steeringWheelElec,
      tornoDiameterTurnable,
      tornoDiameterBush,
      tornoDiameterCar,
      tornoDiameterBarPass,
      spindleRPM,
      spindleMotorPotence,
      spindleDiameter,
      spindleNoseType,
      spindleLiveTools,
      spindleLiveToolsQuantity,
      spindleLiveToolsRPM,
      torque,
      coneType,
      spindleType,
      spindleElectro,
      spindleElectroBrand,
      spindleElectroCoolerType,
      spindleInterfaceType,
      subSpindle,
      subSpindleDiameterTornable,
      subSpindleDiameterBush,
      subSpindleDiameterCar,
      subSpindleDiameterBar,
      subSpindleNoseType,
      subSpindleLiveTools,
      subSpindleLiveToolsQuantity,
      subSpindleLiveToolsRPM,
      subSpindleRPM,
      subSpindleMoterPower,
      subSpindleInterfaceType,
      subSpindlePartCatcher,
      subSpindleTravelAxisLength,
      contraPonto,
      contraPontoType,
      contraPontoPath,
      contraPontoProgrammablePositioning,
      grindDimensionX,
      grindDimensionY,
      grindDimensionZ,
      grindTableDistance,
      grindMinVerticalAdd,
      grindRotation,
      gridPotence,
      gridRPM,
      gridMotorPotence,
      gridRectifierAuto,
      gridBalanceSystem,
      gridFilteringSystem,
      gridFilteringSystemVacuum,
      gridFilteringSystemPaper,
      gridFilteringSystemMagnetic,
      gridFilteringSystemOther,
      gridFilteringSystemOtherValue,
      gridRectifierMaxDiameter,
      gridCentersHeight,
      gridCentersInternalRetifierDiameterMin,
      gridCentersInternalRetifierDiameterMax,
      gridRetifierAngleMin,
      gridRetifierAngleMax,
      gridMaxPartWeight,
      gridCarCourse,
      gridAutoPassing,
      gridCenterHeadType,
      gridCenterContraPontoType,
      gridCenterContraPontoPath,
      gridBalancerIncluded,
      closeUnitPlateDimensionH,
      closeUnitPlateDimensionV,
      closeUnitColumnDistanceH,
      closeUnitColumnDistanceV,
      closeUnitMoldTicknessMin,
      closeUnitMoldTicknessMax,
      closeUnitMinMoldDimension,
      closeUnitForce,
      openUnitForce,
      closeUnitSupPlateCourse,
      closeUnitSupPlateMove,
      closeUnitMobilePlateCourse,
      closeUnitMaxDistanceBetween,
      closeUnitExtractorQuantity,
      closeUnitExtractorForce,
      closeUnitExtractionCourse,

      barSupplierBrand,
      barSupplierModel,
      barSupplierMaxDiameter,
      barSupplierMaxHeight,
      barSupplierDimentionC,
      barSupplierDimentionL,
      barSupplierDimentionA,
      barSupplierWeight,
          
      erosionHeadAxisC360,
      erosionHeadType,
      erosionHeadSubType,
      erosionThreadMin,
      erosionThreadMax,
      erosionThreadingAuto,
      erosionRodMin,
      erosionRodMax,
      erosionRodAuto,
      sawType,
      sawVelocityVariation,
      sawVelocityVariationIMin,
      sawVelocityVariationIMax,
      sawAutoSupply,
      sawCutDimensionsMinDiameter,
      sawCutDimensionsMinRectangle,
      sawCutDimensionsMaxDiameter,
      sawCutDimensionsMaxRectangle,
      sawCutMinLength,
      sawCutMaxLength,
      sawAngleCut,
      sawAngleCutMin,
      sawAngleCutMax,
      sawTapeDimensionsC,
      sawTapeDimensionsL,
      sawTapeDimensionsA,
      sawDimensions,
      sawWeight,

      generatorHasRefrigerator,
      generatorAMP,

      injectionUnitFuseDiameter,
      injectionUnitMaxRPMFuse,
      injectionUnitVolume,
      injectionUnitMaxWeight,
      injectionUnitPression,
      injectionUnitRatio,
      injectionCenterRingDiameter,
      injectionScrewStrock,
      injectionRate,
      injectionNozzleContactForce,
      injectionNozzleStroke,
      injectionResineSystem,
      ndInjectionUnit,
      ndInjectionUnitFuseDiameter,
      ndInjectionUnitMaxRPMFuse,
      ndInjectionUnitVolume,
      ndInjectionUnitMaxWeight,
      ndInjectionUnitPression,
      ndInjectionUnitRatio,
      ndInjectionCenterRingDiameter,
      ndInjectionScrewStrock,
      ndInjectionRate,
      ndInjectionNozzleContactForce,
      ndInjectionNozzleStroke,
      ndInjectionResineSystem,
      pressDimensionLength,
      pressDimensionWidth,
      pressDimensionHeight,
      pressWeight,
  
      dimensionC,
      dimensionL,
      dimensionA,
      weight,
      deliveryDate,
      warranty,
      transportPrice,
      paymentConditions,
      commands,
    
      observations,
      youtubeLink,
      catalogOriginalname,
      catalogFilename,
    
      files,
      filesLoading,

      marketplaceVisibility,
      marketplaceEmphasis,
      marketplacePriceVisibillity,
      marketplacePriceType,
      options,

      step,

      submitted,
      dirty,
    } = this.state;
    const { machine, machineStatus } = this.props;
    const {
      costsWarrantyMaterial,
      costsWarrantyTravels,
      costsWarrantyManHours,
      costsWarrantyOthers,
      costsSellMaterial,
      costsSellTravels,
      costsSellManHours,
      costsSellOthers,
      costsInvoicingMaterial,
      costsInvoicingTravels,
      costsInvoicingManHours,
      costsInvoicingOthers,
    } = machine || {};

    const activeStep = steps.filter((s) => s.present.indexOf(type as MachineTypes) !== -1).findIndex((s) => s.id === step);

    return (
      <>
        <Prompt
          when={dirty}
          message='Existem alterações que não foram gravadas. Tem a certeza que pretende sair do formulário?'
        />

        <Box>
          <Typography variant="h6" component="h2">Máquinas</Typography>
          <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard/machines/list">
              Lista de Máquinas
            </Link>
            <Typography color="textPrimary">{id ? `Editar ${id}` : 'Adicionar'}</Typography>
          </Breadcrumbs>

          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/machines/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              {activeStep > 0 && (
                <Box mr={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<ChevronLeftIcon />}
                    onClick={this.handlePrev}
                  >Anterior</Button>
                </Box>
              )}
              <Box>
                {type && activeStep === steps.filter((s) => s.present.indexOf(type as MachineTypes) !== -1).length - 1 ? (
                  <Button
                    disabled={loading}
                    variant="contained"
                    color="secondary"
                    startIcon={<SaveIcon />}
                    onClick={this.handleSubmit}
                  >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
                ) : (
                  <Button
                    disabled={!type}
                    variant="contained"
                    color="secondary"
                    startIcon={<ChevronRightIcon />}
                    onClick={this.handleNext}
                  >Próximo</Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2} mb={2}>
          <Card>
            <CardContent>
              <Box m={2}>
                <FormControl fullWidth>
                  <InputLabel id="marchine-type">Tipo de Máquina</InputLabel>
                  <Select
                    labelId="marchine-type"
                    disabled={inited}
                    value={type}
                    onChange={(event: any) => this.handleChange('type', event.target.value)}
                  >
                    <MenuItem value="" disabled>Nenhum</MenuItem>
                    {machineTypes.map((mt) => <MenuItem key={mt.id} value={mt.id}>{mt.label}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>
            </CardContent>
          </Card>

          {inited && type.length > 0 && (
            <>
              <Box pt={2}>
                <Stepper alternativeLabel activeStep={activeStep} style={{ backgroundColor: 'transparent' }}>
                  {steps.filter((s) => s.present.indexOf(type as MachineTypes) !== -1).map((s) => (
                    <Step key={s.id} onClick={() => id ? this.changeStep(s.id) : undefined}>
                      <StepLabel style={{ cursor: id ? 'pointer' : 'inherit' }} StepIconComponent={(props: { active: boolean; completed: boolean }) => {
                        const iconClass = className('stepper-icon', {
                          active: !!props.active,
                          completed: !!props.completed
                        });

                        return (
                          <div
                            className={iconClass}
                            onClick={props.completed ? () => this.changeStep(s.id) : undefined}
                          >{s.icon}</div>
                        );
                      }}>{s.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
  
              {step === 'info' ? (
                <>
                  <FormInfo
                    type={type}

                    description={description}
                    supplierId={supplierId}
                    brand={brand}
                    model={model}
                    serialnumber={serialnumber}
                    commandType={commandType}
                    commandBrand={commandBrand}
                    commandModel={commandModel}

                    suppliers={suppliers}
                    handleChange={this.handleChange}
                  
                    submitted={submitted}
                  />
                  <FormInfoPrices
                    buyPrice={buyPrice}
                    installBuyPrice={installBuyPrice}
                    truckBuyPrice={truckBuyPrice}
                    isMachineNew={isMachineNew}
                    machineYear={machineYear}
                    machineUsageHours={machineUsageHours}
                    status={status}

                    machineStatus={machineStatus}
                    handleChange={this.handleChange}
                  
                    submitted={submitted}
                  />
                  <FormInfoCosts
                    costsWarrantyMaterial={costsWarrantyMaterial}
                    costsWarrantyTravels={costsWarrantyTravels}
                    costsWarrantyManHours={costsWarrantyManHours}
                    costsWarrantyOthers={costsWarrantyOthers}
                    costsSellMaterial={costsSellMaterial}
                    costsSellTravels={costsSellTravels}
                    costsSellManHours={costsSellManHours}
                    costsSellOthers={costsSellOthers}
                    costsInvoicingMaterial={costsInvoicingMaterial}
                    costsInvoicingTravels={costsInvoicingTravels}
                    costsInvoicingManHours={costsInvoicingManHours}
                    costsInvoicingOthers={costsInvoicingOthers}
                    costsOthersMaterial={costsOthersMaterial}
                    costsOthersTravels={costsOthersTravels}
                    costsOthersManHours={costsOthersManHours}
                    costsOthersOthers={costsOthersOthers}
            
                    handleChange={this.handleChange}
                  />
                </>
              ) : null}
              {step === 'details' ? (
                <FormDetails
                  type={type}

                  courseX={courseX}
                  courseY={courseY}
                  courseZ={courseZ}
                  course4Axis={course4Axis}
                  course4AxisMM={course4AxisMM}
                  course5Axis={course5Axis}
                  axisType={axisType}
                  positioning4Axis={positioning4Axis}
                  positioning5Axis={positioning5Axis}
                  autoPalletExchange={autoPalletExchange}
                  autoPalletExchangeQuantity={autoPalletExchangeQuantity}
                  autoPalletExchangeDimension={autoPalletExchangeDimension}
                  tableMeasurement={tableMeasurement}
                  tableDimensionX={tableDimensionX}
                  tableDimensionY={tableDimensionY}
                  tableDimensionDiameter={tableDimensionDiameter}
                  spindleTableDistance={spindleTableDistance}
                  spindleTableDistanceMin={spindleTableDistanceMin}
                  spindleTableDistanceMax={spindleTableDistanceMax}
                  rotativeTable={rotativeTable}
                  rotativeTableAngleMinPositioning={rotativeTableAngleMinPositioning}
                  robot={robot}
                  robotNumberOfEletrods={robotNumberOfEletrods}
                  robotNumberOfPallets={robotNumberOfPallets}
                  robotNumberOfAxis={robotNumberOfAxis}
                  robotReach={robotReach}
                  tankDimensionLength={tankDimensionLength}
                  tankDimensionWidth={tankDimensionWidth}
                  tankDimensionHeight={tankDimensionHeight}
                  tankSubmersible={tankSubmersible}
                  tankSubmersibleAuto={tankSubmersibleAuto}
                  portableSteeringWheel={portableSteeringWheel}
                  partMaxHeight={partMaxHeight}
                  dielectricQuantity={dielectricQuantity}
                  hasCorePullers={hasCorePullers}
                  corePullersSideMobile={corePullersSideMobile}
                  corePullersSideFixed={corePullersSideFixed}
                  corePullersQuantity={corePullersQuantity}
                  hasPneumaticValves={hasPneumaticValves}
                  pneumaticValvesSideMobile={pneumaticValvesSideMobile}
                  pneumaticValvesSideFixed={pneumaticValvesSideFixed}
                  pneumaticValvesQuantity={pneumaticValvesQuantity}
                  hasControlValveGate={hasControlValveGate}
                  controlValveGateQuantity={controlValveGateQuantity}
                  hasMoldTemperatureControl={hasMoldTemperatureControl}
                  moldTemperatureControlZones={moldTemperatureControlZones}
                  maxHydricPression={maxHydricPression}
                  heatingPower={heatingPower}
                  oilTankCapacity={oilTankCapacity}
                  motorPump={motorPump}
                  motorPower={motorPower}
                  tempControlZones={tempControlZones}
                  magneticTableDimensionLength={magneticTableDimensionLength}
                  magneticTableDimensionWidth={magneticTableDimensionWidth}
                  magneticTableWeight={magneticTableWeight}
                  spindleTableWeight={spindleTableWeight}
                  rapidAxisVelocityX={rapidAxisVelocityX}
                  rapidAxisVelocityY={rapidAxisVelocityY}
                  rapidAxisVelocityZ={rapidAxisVelocityZ}
                  programmableVelocityAxisX={programmableVelocityAxisX}
                  programmableVelocityAxisY={programmableVelocityAxisY}
                  programmableVelocityAxisZ={programmableVelocityAxisZ}
                  rapidAxisVelocityC={rapidAxisVelocityC}
                  programmableVelocityAxisC={programmableVelocityAxisC}
                  rapidAxisVelocityB={rapidAxisVelocityB}
                  programmableVelocityAxisB={programmableVelocityAxisB}
                  transmissionMotor4Axis={transmissionMotor4Axis}
                  transmissionMotor5Axis={transmissionMotor5Axis}
                  cutMaxVelocity={cutMaxVelocity}
                  cutMaxAngle={cutMaxAngle}
                  cutMaxAngleByLength={cutMaxAngleByLength}
                  turningDiameter={turningDiameter}
                  turningLength={turningLength}
                  detailsRPM={detailsRPM}
                  detailsPotence={detailsPotence}
                  detailsTorque={detailsTorque}
                  detailsConeType={detailsConeType}
                  headtype={headtype}
                  guidesWidth={guidesWidth}
                  RPMAxisC={RPMAxisC}
                  maximumFeedrate={maximumFeedrate}
                  connectionPower={connectionPower}
          
                  handleChange={this.handleChange}
                />
              ) : null}
              {step === 'turreta' ? (
                <FormTurreta
                  type={type}
          
                  tornTurret={tornTurret}
                  toolQuantity={toolQuantity}
                  warehouseType={warehouseType}
                  tollExchangeTime={tollExchangeTime}
                  supportType={supportType}
                  staticSupportNumber={staticSupportNumber}
                  motorTool={motorTool}
                  motorToolRPM={motorToolRPM}
                  motorToolPotence={motorToolPotence}
                  motorToolSupports={motorToolSupports}
                  motorToolNew={motorToolNew}
                  motorToolNewSupportName={motorToolNewSupportName}
                  motorToolNewSupportDescription={motorToolNewSupportDescription}
                  motorToolNewSupportBuyPrice={motorToolNewSupportBuyPrice}
                  motorToolNewSupportSellPrice={motorToolNewSupportSellPrice}
                  motorToolRadQuantity={motorToolRadQuantity}
                  motorToolAxiQuantity={motorToolAxiQuantity}
                  electrodeWarehouse={electrodeWarehouse}
                  electrodeWarehouseQuantity={electrodeWarehouseQuantity}
                  electrodeMaxHeight={electrodeMaxHeight}
                  electrodeMaxErosionHead={electrodeMaxErosionHead}
              
                  handleChange={this.handleChange}
                />
              ) : null}
              {step === 'fuses' ? (
                <FormFuses
                  type={type}
          
                  highPressureInteriorRefrigeration={highPressureInteriorRefrigeration}
                  highPressureInteriorRefrigerationBar={highPressureInteriorRefrigerationBar}
                  wasteExtractor={wasteExtractor}
                  wasteExtractorType={wasteExtractorType}
                  toolMeasurer={toolMeasurer}
                  toolMeasurerType={toolMeasurerType}
                  autoCenter={autoCenter}
                  autoCenterBrand={autoCenterBrand}
                  cabinada={cabinada}
                  partCatcher={partCatcher}
                  toolMeasurerArm={toolMeasurerArm}
                  toolMeasurerArmAutomatic={toolMeasurerArmAutomatic}
                  steeringWheelElec={steeringWheelElec}
                  tornoDiameterTurnable={tornoDiameterTurnable}
                  tornoDiameterBush={tornoDiameterBush}
                  tornoDiameterCar={tornoDiameterCar}
                  tornoDiameterBarPass={tornoDiameterBarPass}
                  spindleRPM={spindleRPM}
                  spindleMotorPotence={spindleMotorPotence}
                  spindleDiameter={spindleDiameter}
                  spindleNoseType={spindleNoseType}
                  spindleLiveTools={spindleLiveTools}
                  spindleLiveToolsQuantity={spindleLiveToolsQuantity}
                  spindleLiveToolsRPM={spindleLiveToolsRPM}
                  torque={torque}
                  coneType={coneType}
                  spindleType={spindleType}
                  spindleElectro={spindleElectro}
                  spindleElectroBrand={spindleElectroBrand}
                  spindleElectroCoolerType={spindleElectroCoolerType}
                  spindleInterfaceType={spindleInterfaceType}
                  subSpindle={subSpindle}
                  subSpindleDiameterTornable={subSpindleDiameterTornable}
                  subSpindleDiameterBush={subSpindleDiameterBush}
                  subSpindleDiameterCar={subSpindleDiameterCar}
                  subSpindleDiameterBar={subSpindleDiameterBar}
                  subSpindleNoseType={subSpindleNoseType}
                  subSpindleLiveTools={subSpindleLiveTools}
                  subSpindleLiveToolsQuantity={subSpindleLiveToolsQuantity}
                  subSpindleLiveToolsRPM={subSpindleLiveToolsRPM}
                  subSpindleRPM={subSpindleRPM}
                  subSpindleMoterPower={subSpindleMoterPower}
                  subSpindleInterfaceType={subSpindleInterfaceType}
                  subSpindlePartCatcher={subSpindlePartCatcher}
                  subSpindleTravelAxisLength={subSpindleTravelAxisLength}
                  contraPonto={contraPonto}
                  contraPontoType={contraPontoType}
                  contraPontoPath={contraPontoPath}
                  contraPontoProgrammablePositioning={contraPontoProgrammablePositioning}
                  grindDimensionX={grindDimensionX}
                  grindDimensionY={grindDimensionY}
                  grindDimensionZ={grindDimensionZ}
                  grindTableDistance={grindTableDistance}
                  grindMinVerticalAdd={grindMinVerticalAdd}
                  grindRotation={grindRotation}
                  gridPotence={gridPotence}
                  gridRPM={gridRPM}
                  gridMotorPotence={gridMotorPotence}
                  gridRectifierAuto={gridRectifierAuto}
                  gridBalanceSystem={gridBalanceSystem}
                  gridFilteringSystem={gridFilteringSystem}
                  gridFilteringSystemVacuum={gridFilteringSystemVacuum}
                  gridFilteringSystemPaper={gridFilteringSystemPaper}
                  gridFilteringSystemMagnetic={gridFilteringSystemMagnetic}
                  gridFilteringSystemOther={gridFilteringSystemOther}
                  gridFilteringSystemOtherValue={gridFilteringSystemOtherValue}
                  gridRectifierMaxDiameter={gridRectifierMaxDiameter}
                  gridCentersHeight={gridCentersHeight}
                  gridCentersInternalRetifierDiameterMin={gridCentersInternalRetifierDiameterMin}
                  gridCentersInternalRetifierDiameterMax={gridCentersInternalRetifierDiameterMax}
                  gridRetifierAngleMin={gridRetifierAngleMin}
                  gridRetifierAngleMax={gridRetifierAngleMax}
                  gridMaxPartWeight={gridMaxPartWeight}
                  gridCarCourse={gridCarCourse}
                  gridAutoPassing={gridAutoPassing}
                  gridCenterHeadType={gridCenterHeadType}
                  gridCenterContraPontoType={gridCenterContraPontoType}
                  gridCenterContraPontoPath={gridCenterContraPontoPath}
                  gridBalancerIncluded={gridBalancerIncluded}
                  closeUnitPlateDimensionH={closeUnitPlateDimensionH}
                  closeUnitPlateDimensionV={closeUnitPlateDimensionV}
                  closeUnitColumnDistanceH={closeUnitColumnDistanceH}
                  closeUnitColumnDistanceV={closeUnitColumnDistanceV}
                  closeUnitMoldTicknessMin={closeUnitMoldTicknessMin}
                  closeUnitMoldTicknessMax={closeUnitMoldTicknessMax}
                  closeUnitMinMoldDimension={closeUnitMinMoldDimension}
                  closeUnitForce={closeUnitForce}
                  openUnitForce={openUnitForce}
                  closeUnitSupPlateCourse={closeUnitSupPlateCourse}
                  closeUnitSupPlateMove={closeUnitSupPlateMove}
                  closeUnitMobilePlateCourse={closeUnitMobilePlateCourse}
                  closeUnitMaxDistanceBetween={closeUnitMaxDistanceBetween}
                  closeUnitExtractorQuantity={closeUnitExtractorQuantity}
                  closeUnitExtractorForce={closeUnitExtractorForce}
                  closeUnitExtractionCourse={closeUnitExtractionCourse}

                  handleChange={this.handleChange}
                />
              ) : null}
              {step === 'barsupplier' ? (
                <FormBarSupplier
                  barSupplierBrand={barSupplierBrand}
                  barSupplierModel={barSupplierModel}
                  barSupplierMaxDiameter={barSupplierMaxDiameter}
                  barSupplierMaxHeight={barSupplierMaxHeight}
                  barSupplierDimentionC={barSupplierDimentionC}
                  barSupplierDimentionL={barSupplierDimentionL}
                  barSupplierDimentionA={barSupplierDimentionA}
                  barSupplierWeight={barSupplierWeight}
            
                  handleChange={this.handleChange}
                />
              ) : null}
              {step === 'erosionhead' ? (
                <FormErosionHead
                  type={type}

                  erosionHeadAxisC360={erosionHeadAxisC360}
                  erosionHeadType={erosionHeadType}
                  erosionHeadSubType={erosionHeadSubType}
                  erosionThreadMin={erosionThreadMin}
                  erosionThreadMax={erosionThreadMax}
                  erosionThreadingAuto={erosionThreadingAuto}
                  erosionRodMin={erosionRodMin}
                  erosionRodMax={erosionRodMax}
                  erosionRodAuto={erosionRodAuto}
                  sawType={sawType}
                  sawVelocityVariation={sawVelocityVariation}
                  sawVelocityVariationIMin={sawVelocityVariationIMin}
                  sawVelocityVariationIMax={sawVelocityVariationIMax}
                  sawAutoSupply={sawAutoSupply}
                  sawCutDimensionsMinDiameter={sawCutDimensionsMinDiameter}
                  sawCutDimensionsMinRectangle={sawCutDimensionsMinRectangle}
                  sawCutDimensionsMaxDiameter={sawCutDimensionsMaxDiameter}
                  sawCutDimensionsMaxRectangle={sawCutDimensionsMaxRectangle}
                  sawCutMinLength={sawCutMinLength}
                  sawCutMaxLength={sawCutMaxLength}
                  sawAngleCut={sawAngleCut}
                  sawAngleCutMin={sawAngleCutMin}
                  sawAngleCutMax={sawAngleCutMax}
                  sawTapeDimensionsC={sawTapeDimensionsC}
                  sawTapeDimensionsL={sawTapeDimensionsL}
                  sawTapeDimensionsA={sawTapeDimensionsA}
                  sawDimensions={sawDimensions}
                  sawWeight={sawWeight}
            
                  handleChange={this.handleChange}
                />
              ) : null}
              {step === 'generator' ? (
                <FormGenerator
                  generatorHasRefrigerator={generatorHasRefrigerator}
                  generatorAMP={generatorAMP}
            
                  handleChange={this.handleChange}
                />
              ) : null}
              {step === 'injectionunit' ? (
                <FormInjectionUnit
                  type={type}

                  injectionUnitFuseDiameter={injectionUnitFuseDiameter}
                  injectionUnitMaxRPMFuse={injectionUnitMaxRPMFuse}
                  injectionUnitVolume={injectionUnitVolume}
                  injectionUnitMaxWeight={injectionUnitMaxWeight}
                  injectionUnitPression={injectionUnitPression}
                  injectionUnitRatio={injectionUnitRatio}
                  injectionCenterRingDiameter={injectionCenterRingDiameter}
                  injectionScrewStrock={injectionScrewStrock}
                  injectionRate={injectionRate}
                  injectionNozzleContactForce={injectionNozzleContactForce}
                  injectionNozzleStroke={injectionNozzleStroke}
                  injectionResineSystem={injectionResineSystem}
                  ndInjectionUnit={ndInjectionUnit}
                  ndInjectionUnitFuseDiameter={ndInjectionUnitFuseDiameter}
                  ndInjectionUnitMaxRPMFuse={ndInjectionUnitMaxRPMFuse}
                  ndInjectionUnitVolume={ndInjectionUnitVolume}
                  ndInjectionUnitMaxWeight={ndInjectionUnitMaxWeight}
                  ndInjectionUnitPression={ndInjectionUnitPression}
                  ndInjectionUnitRatio={ndInjectionUnitRatio}
                  ndInjectionCenterRingDiameter={ndInjectionCenterRingDiameter}
                  ndInjectionScrewStrock={ndInjectionScrewStrock}
                  ndInjectionRate={ndInjectionRate}
                  ndInjectionNozzleContactForce={ndInjectionNozzleContactForce}
                  ndInjectionNozzleStroke={ndInjectionNozzleStroke}
                  ndInjectionResineSystem={ndInjectionResineSystem}
                  pressDimensionLength={pressDimensionLength}
                  pressDimensionWidth={pressDimensionWidth}
                  pressDimensionHeight={pressDimensionHeight}
                  pressWeight={pressWeight}
                          
                  handleChange={this.handleChange}
                />
              ) : null}
              {step === 'logistics' ? (
                <FormLogistics
                  dimensionC={dimensionC}
                  dimensionL={dimensionL}
                  dimensionA={dimensionA}
                  weight={weight}
                  deliveryDate={deliveryDate}
                  warranty={warranty}
                  transportPrice={transportPrice}
                  paymentConditions={paymentConditions}
                  commands={commands}
                                  
                  handleChange={this.handleChange}
                />
              ) : null}
              {step === 'optionals' ? (
                <FormOptionals
                  id={id}
                  
                  observations={observations}
                  youtubeLink={youtubeLink}
                  catalogOriginalname={catalogOriginalname}
                  catalogFilename={catalogFilename}
                  files={files}
                  filesLoading={filesLoading}
                  marketplaceVisibility={marketplaceVisibility}
                  marketplaceEmphasis={marketplaceEmphasis}
                  marketplacePriceVisibillity={marketplacePriceVisibillity}
                  marketplacePriceType={marketplacePriceType}
                  options={options}

                  submitted={submitted}
            
                  handleChange={this.handleChange}
                  handleFileChange={this.handleFileChange}
                  handleFiles={this.handleFiles}
                  handleOptionChange={this.handleOptionChange}
                  handleOptionAdd={this.handleOptionAdd}
                  handleOptionRemove={this.handleOptionRemove}
                />
              ) : null}
            </>
          )}
        </Box>

        <Grid style={{ justifyContent: 'flex-end' }} container>
          <Grid style={{ display: 'flex' }} item>
            <Box mr={1} pb={2}>
              <Link to="/dashboard/machines/list">
                <Button color="default" variant="contained">Cancelar</Button>
              </Link>
            </Box>
            {activeStep > 0 && (
              <Box mr={1} pb={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<ChevronLeftIcon />}
                  onClick={this.handlePrev}
                >Anterior</Button>
              </Box>
            )}
            <Box pb={2}>
              {type && activeStep === steps.filter((s) => s.present.indexOf(type as MachineTypes) !== -1).length - 1 ? (
                <Button
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                  startIcon={<SaveIcon />}
                  onClick={this.handleSubmit}
                >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
              ) : (
                <Button
                  disabled={!type}
                  variant="contained"
                  color="secondary"
                  startIcon={<ChevronRightIcon />}
                  onClick={this.handleNext}
                >Próximo</Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default Form;
