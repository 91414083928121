import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  CreateCustomerProposalRequest,
  createCustomerProposalRequest,
  EditCustomerProposalRequest,
  editCustomerProposalRequest,
  fetchCustomers,
  fetchUsers,
} from '../../../../../actions/index';
import { IAppState } from '../../../../../reducers/index';
import Form from './Form';

interface IProps {
  proposalrequestid?: string;
}

const mapStateToProps = ({ customers, users }: IAppState, props: IProps) => ({
  customers: Object.values(customers.customers),
  loading: customers.loading || customers.proposalRequests.loading,
  users: Object.values(users.users),
  redirectReady: customers.proposalRequests.form.redirectReady,
  proposalRequest: customers.proposalRequests.proposalRequests[Number(props.proposalrequestid || 0)],
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    createCustomerProposalRequest: (properties: CreateCustomerProposalRequest) =>
      dispatch(createCustomerProposalRequest.request(properties)),
    editCustomerProposalRequest: (properties: EditCustomerProposalRequest) =>
      dispatch(editCustomerProposalRequest.request(properties)),
    fetchCustomers: () => dispatch(fetchCustomers.request()),
    fetchUsers: () => dispatch(fetchUsers.request()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
