import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleProposalRequestView } from '../../../../../actions/customers';
import { IAppState } from '../../../../../reducers/index';
import View from './ViewModal';

const mapStateToProps = ({ customers }: IAppState) => ({
  customers: customers.customers,
  proposalRequest: customers.proposalRequests.proposalRequests[customers.proposalRequests.view.proposalRequestId || 0] || {},
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleProposalRequestView: (proposalRequestId?: number) =>
      dispatch(toggleProposalRequestView(proposalRequestId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);