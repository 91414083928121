import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUserTag } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import { CustomerWithMeta } from '../../../../models/Customer';
import User from '../../../../models/User';
import { ZoneMeta } from '../../../../models/Zone';
import { activityTypes, countries } from '../../../../data';
import Button from '../../../common/Button';

interface IProps {
  customer: CustomerWithMeta;
  toggleCustomerView: (customerId?: number) => void;
  fetchZoneList: () => void;
  loading: boolean;
  user: User;
  zones: ZoneMeta[];
}

class ViewModal extends PureComponent<IProps> {
  public componentDidMount() {
    const { fetchZoneList } = this.props;

    fetchZoneList();
  }

  public render() {
    const { customer, loading, toggleCustomerView, user, zones } = this.props;
    const {
      id,
      activityType,
      commercialUserName,
      hasVat,
      language,
      address,
      city,
      country,
      coordinates,
      customerTaxNumber,
      email,
      name,
      telephone,
      zoneId,
      company,
      status,
    } = customer;

    return (
      <Dialog open onClose={() => toggleCustomerView()}>
        <DialogTitle>
          <FontAwesomeIcon className="header-icon" icon={faUserTag} />
          <span style={{ marginLeft: '8px' }}>Detalhes de cliente - {name}</span>
          <IconButton edge="end" size="small" className="dialog-close-button" onClick={() => toggleCustomerView()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading
          ? <CircularProgress />
          : (
            <Grid container spacing={1} alignItems="center">
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Nome da empresa</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{name}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Telefone</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{telephone}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Email</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{email}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Número de contribuinte</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{customerTaxNumber}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Tipo de actividade</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{
                  (activityTypes.find((a) => a.value === activityType) || {}).label
                }</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Comercial</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{commercialUserName}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Tem IVA</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{hasVat ? 'Sim' : 'Não'}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Morada</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{address}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Cidade</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{city}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>País</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{(countries.find((c) => c.value === country) || {}).label}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Zona</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{(zones.find((z) => z.id === zoneId) || {}).name}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Coordenadas</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{coordinates}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Língua</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{(language || 'pt').toUpperCase()}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Empresa</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{company === 'gotec' ? 'GoTec' : company === 'ais' ? 'AIS' : ''}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">
                  <b>Estado</b>
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body2">{status}</Typography>
              </Grid>
              <Grid item sm={12}>
                <Divider />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <Box p={2} pt={0}>
          <DialogActions>
            {user.role !== 'tech' ? (
              <Box mr={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  startIcon={<FontAwesomeIcon icon={faPencilAlt} />}
                  component={Link}
                  to={`/dashboard/customers/customerid/${id}/edit`}
                >Editar</Button>
              </Box>
            ) : null}
            <Button color="default" variant="contained" onClick={() => toggleCustomerView()}>Fechar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
}

export default ViewModal;