import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faUserTag, faUser } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { DatePicker } from '@material-ui/pickers';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Prompt } from 'react-router';

import { CreateCustomerProposalRequest, EditCustomerProposalRequest } from '../../../../../actions/customers';
import Customer from '../../../../../models/Customer';
import ProposalRequest from '../../../../../models/ProposalRequest';
import Button from '../../../../common/Button';
import { parse } from 'qs';
import { machineTypes } from '../../../Machines/sources';
import User from '../../../../../models/User';

interface IProps {
  createCustomerProposalRequest: (properties: CreateCustomerProposalRequest) => void;
  customers: Customer[];
  proposalRequest?: ProposalRequest;
  editCustomerProposalRequest: (properties: EditCustomerProposalRequest) => void;
  fetchCustomers: () => void;
  fetchUsers: () => void;
  users: User[];
  history: any;
  location: any;
  loading: boolean;
  redirectReady: boolean;
}
interface IState {
  id?: number;
  customerId?: number;
  dueDate: Date;
  machineType?: string;
  otherMachineType?: string;
  commercialUserId?: number;
  description?: string;
  submitted: boolean;
  dirty: boolean;
}

class Form extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const {
      id,
      dueDate,
      customerId,
      machineType,
      otherMachineType,
      commercialUserId,
      description,
    } = props.proposalRequest || {};

    
    const query = parse(props.location.search, { ignoreQueryPrefix: true });

    const state = {
      id,
      dueDate: dueDate || new Date(),
      customerId,
      machineType,
      otherMachineType,
      commercialUserId,
      description,
      submitted: false,
      dirty: false,
    };

    this.state = query.origin === 'customer'
      ? {
        ...JSON.parse(localStorage.getItem('proposalRequestToCustomer') || JSON.stringify(state)),
        customerId: query.customerId && !isNaN(Number(query.customerId)) ? Number(query.customerId) : undefined
      }
      : state;

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addCustomer = this.addCustomer.bind(this);
  }

  public componentDidMount() {
    const { fetchCustomers, fetchUsers } = this.props;

    fetchCustomers();
    fetchUsers();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { proposalRequest, history, redirectReady } = this.props;

    const {
      id,
      dueDate,
      customerId,
      machineType,
      otherMachineType,
      commercialUserId,
      description
    } = proposalRequest || {};


    if (JSON.stringify(prevProps.proposalRequest) !== JSON.stringify(proposalRequest)) {
      this.setState({
        id,
        dueDate: dueDate || new Date(),
        customerId,
        machineType,
        otherMachineType,
        commercialUserId,
        description,
        submitted: false,
      });
    }

    if (redirectReady !== prevProps.redirectReady && redirectReady) {
      this.setState({ dirty: false }, () => {
        history.push('/dashboard/customers/proposal-requests/list');
      });
    }
  }

  public addCustomer() {
    const { history } = this.props;
    const { id } = this.state;

    localStorage.setItem('proposalRequestToCustomer', JSON.stringify(this.state)); 

    history.push(`/dashboard/customers/new?origin=proposal-request${id ? '&proposalRequestId=' + id : ''}`);
  }

  public hasErrors() {
    const { dueDate, customerId, machineType, otherMachineType, description } = this.state;

    if (
      !dueDate ||
      !customerId ||
      !machineType ||
      (machineType === 'other' && !otherMachineType) ||
      !description
    ) {
      return true;
    }

    return false;
  }

  public handleSubmit() {
    const { createCustomerProposalRequest, editCustomerProposalRequest } = this.props;
    const {
      id,
      dueDate,
      customerId,
      machineType,
      otherMachineType,
      commercialUserId,
      description,
    } = this.state;

    if (this.hasErrors()) {
      return this.setState({ submitted: true });
    }

    if (id) {
      editCustomerProposalRequest({
        id,
        dueDate,
        customerId: customerId || 0,
        machineType: machineType || 'other',
        otherMachineType: machineType === 'other' ? otherMachineType : undefined,
        commercialUserId,
        description: description || 'NA',
      });
    } else {
      createCustomerProposalRequest({
        dueDate,
        customerId: customerId || 0,
        machineType: machineType || 'other',
        otherMachineType: machineType === 'other' ? otherMachineType : undefined,
        commercialUserId,
        description: description || 'NA',
      });
    }
  }

  public handleChange(field: string, event: any) {
    this.setState({
      [field]: event.target.value,
      dirty: true,
    } as Pick<IState, 'dirty'>);
  }

  public handleDateChange(field: string, date: Date) {
    this.setState({
      [field]: date,
      dirty: true,
    } as Pick<IState, 'dirty'>);
  }

  public handleSelect(field: string, value: any) {
    this.setState({ [field]: value, dirty: true } as Pick<IState, 'dirty'>);
  }

  public render() {
    const { customers, loading, users } = this.props;
    const {
      id,
      dueDate,
      customerId,
      machineType,
      otherMachineType,
      commercialUserId,
      description,
      submitted,
      dirty,
    } = this.state;

    if (!customerId && customers.length === 1) {
      this.handleSelect('customerId', customers[0].id);
    }

    return (
      <div className="dashboard-container">
        <Prompt
          when={dirty}
          message='Existem alterações que não foram gravadas. Tem a certeza que pretende sair do formulário?'
        />

        <Box>
          <Typography variant="h6" component="h2">Clientes</Typography>
          <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard/customers/proposal-requests/list">
              Pedidos de Proposta
            </Link>
            <Typography color="textPrimary">{id ? `Editar #${id}` : 'Adicionar'}</Typography>
          </Breadcrumbs>

          <Grid style={{ justifyContent: 'flex-end' }} container>
            <Grid style={{ display: 'flex' }} item>
              <Box mr={1}>
                <Link to="/dashboard/customers/proposal-requests/list">
                  <Button color="default" variant="contained">Cancelar</Button>
                </Link>
              </Box>
              <Button
                disabled={loading}
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
                onClick={this.handleSubmit}
              >{loading ? <CircularProgress size={24} /> : 'Gravar'}</Button>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <Box p={2}>
                <Typography className="subtitle" component="b">Pedido de Proposta</Typography>
              </Box>

              <Box m={2}>
                <Grid container spacing={4}>
                  <Grid sm={6} item>
                    <FormControl margin="normal" fullWidth>
                      <DatePicker
                        error={submitted && !dueDate}
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        required
                        label="Prazo de Resposta"
                        value={dueDate}
                        onChange={(date: any) => this.handleDateChange('dueDate', date)}
                        helperText={submitted && !dueDate ? 'Deve introduzir o prazo de resposta' : ''}
                      />
                    </FormControl>
                  </Grid>
                  <Grid sm={6} item>
                    <FormControl fullWidth>
                      <InputLabel id="marchine-type">Tipo de Máquina</InputLabel>
                      <Select
                        error={submitted && !machineType}
                        labelId="marchine-type"
                        value={machineType}
                        onChange={(event: any) => this.handleDateChange('machineType', event.target.value)}
                      >
                        <MenuItem value="" disabled>Nenhum</MenuItem>
                        {machineTypes.map((mt) => <MenuItem key={mt.id} value={mt.id}>{mt.label}</MenuItem>)}
                        <MenuItem value="other">Outro</MenuItem>
                      </Select>
                    </FormControl>

                    {machineType === 'other' && (
                      <Box mt={2}>
                        <FormControl fullWidth>
                          <TextField
                            label="Tipo de Máquina (outro)"
                            value={otherMachineType || ''}
                            variant="standard"
                            multiline={true}
                            inputProps={{ maxLength: 255 }}
                            onChange={(event: any) => this.handleChange('otherMachineType', event)}
                            error={submitted && !otherMachineType}
                            helperText={submitted && !otherMachineType ? 'Deve indicar o tipo de máquina' : ''}
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Grid>
                  <Grid sm={6} item>
                    <FormControl margin="normal" fullWidth>
                      <Autocomplete
                        autoComplete
                        clearOnEscape
                        openOnFocus
                        options={customers}
                        value={customers.find((c) => c.id === customerId) || null}
                        getOptionLabel={(option: Customer) => option.name}
                        onChange={(event, value: any) => this.handleSelect('customerId', value ? value.id : undefined)}
                        renderOption={(option) => {
                          const customer = customers.find((c) => c.id === option.id) || {} as Customer;

                          return (
                            <Typography variant="body1">
                              {option.name}
                              {!customer.active ? <Typography variant="caption" style={{ color: '#ccc', marginLeft: '7px' }}>(Cliente inactivo)</Typography> : null}
                            </Typography>
                          );
                        }}
                        renderInput={(params: any) =>
                          <TextField
                            {...params}
                            label="Cliente"
                            required
                            error={submitted && !customerId}
                            helperText={submitted && !customerId ? 'Deve seleccionar um cliente' : ''}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon className="form-icon" icon={faUserTag} />
                                </InputAdornment>
                              )
                            }}
                          />
                        }
                      />
                    </FormControl>
                    <CardActions style={{ flexDirection: 'row-reverse' }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<AddIcon />}
                        onClick={this.addCustomer}
                      >
                        Adicionar Cliente
                      </Button>
                    </CardActions>
                  </Grid>
                  <Grid sm={6} item>
                    <FormControl margin="normal" fullWidth>
                      <Autocomplete
                        autoComplete
                        clearOnEscape
                        openOnFocus
                        options={users.filter((u) => u.role === 'commercial' || u.role === 'admincommercial' || u.role === 'admin')}
                        value={users.find((c) => c.id === commercialUserId) || null}
                        getOptionLabel={(option: User) => option.name}
                        onChange={(event, value: any) => this.handleSelect('commercialUserId', value ? value.id : undefined)}
                        renderInput={(params: any) =>
                          <TextField
                            {...params}
                            label="Comercial responsável"
                            error={submitted && !commercialUserId}
                            helperText={submitted && !commercialUserId ? 'Deve seleccionar um comercial responsável' : ''}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FontAwesomeIcon className="form-icon" icon={faUser} />
                                </InputAdornment>
                              )
                            }}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid sm={12} item>
                    <FormControl fullWidth>
                      <TextField
                        label="Descrição"
                        value={description || ''}
                        variant="standard"
                        multiline={true}
                        minRows={3}
                        inputProps={{ maxLength: 3000 }}
                        onChange={(event: any) => this.handleChange('description', event)}
                        error={submitted && !description}
                        helperText={submitted && !description ? 'Deve inserir uma descrição' : ''}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </div>
    );
  }
};

export default Form;
