import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faChevronRight, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable, { Display, MUIDataTableColumn } from 'mui-datatables';
import { parse } from 'qs';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import ProposalRequest from '../../../../../models/ProposalRequest';
import { TableFilter } from '../../../../../models/TableFilter';
import Button from '../../../../common/Button';
import TableButton from '../../../../common/TableButton';
import { Column } from '../../../../common/types';
import { tableTranslations } from '../../../../common/utils';
import ViewModal from '../ViewModal';

import './List.css';
import { machineTypes } from '../../../Machines/sources';

interface IProps {
  history: any;
  location: any;
  match: any;
  proposalRequests: ProposalRequest[];
  fetchCustomers: () => void;
  loading: boolean;
  toggleProposalRequestView: (proposalId?: number) => void;
  view?: number;
}
interface IState {
  columns: Column[];
  filter: TableFilter;
}

class List extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { id } = parse(props.location.search, { ignoreQueryPrefix: true });

    const filter = this.getTableState();
    const columns = this.apendFilterListToColumns([
      { name: 'id', label: 'ID', options: { display: (localStorage.getItem('proposal-requests_list_columns_id') || 'true') as Display, filterList: id ? [String(id)] : [] } },
      { name: 'dueDate', label: 'Prazo de Resposta', options: { display: (localStorage.getItem('proposal-requests_list_columns_date') || 'true') as Display } },
      { name: 'machineType', label: 'Machine Type', options: { display: (localStorage.getItem('proposal-requests_list_columns_machineType') || 'true') as Display } },
      { name: 'customerName', label: 'Cliente', options: { display: (localStorage.getItem('proposal-requests_list_columns_customerName') || 'true') as Display } },
      { name: 'commercialUserName', label: 'Comercial', options: { display: (localStorage.getItem('proposal-requests_list_columns_commercialUserName') || 'true') as Display } },
      { name: 'description', label: 'Description', options: { display: (localStorage.getItem('proposal-requests_list_columns_description') || 'true') as Display } },
      {
        name: 'actions',
        label: 'Acções',
        options: {
          display: (localStorage.getItem('proposal-requests_list_columns_actions') || 'true') as Display,
          filter: false,
          customHeadRender: (columnMeta) => (
            <TableCell key="-1">
              <div style={{ width: '130px'}}>{columnMeta.label}</div>
            </TableCell>
          ),
        },
      },
    ], filter.filterList, false);

    this.state = {
      columns,
      filter,
    };

    this.onColumnViewChange = this.onColumnViewChange.bind(this);
    this.customRowRender = this.customRowRender.bind(this);
    this.apendFilterListToColumns = this.apendFilterListToColumns.bind(this);
    this.setTableState = this.setTableState.bind(this);
  }

  public componentDidMount() {
    const { fetchCustomers } = this.props;

    fetchCustomers();
  }

  public onColumnViewChange(field: string, action: string) {
    const { columns } = this.state;

    columns.find((c) => c.name === field)!.options.display = String(action !== 'remove') as
      | 'true'
      | 'false';

    localStorage.setItem(`proposal-requests_list_columns_${field}`, String(columns.find((c) => c.name === field)!.options.display));

    this.setState({ columns });
  }

  public customRowRender(rowData: any[], _: number, rowIndex: number) {
    const { columns } = this.state;
    const { toggleProposalRequestView } = this.props;

    return (
      <TableRow key={rowIndex}>
        {columns[0].options.display === 'true' && <TableCell>{rowData[0]}</TableCell>}
        {columns[1].options.display === 'true' && <TableCell>{format(new Date(rowData[1]), 'yyyy-MM-dd')}</TableCell>}
        {columns[2].options.display === 'true' && <TableCell>{rowData[2]}</TableCell>}
        {columns[3].options.display === 'true' && <TableCell>{rowData[3]}</TableCell>}
        {columns[4].options.display === 'true' && <TableCell>{rowData[4]}</TableCell>}
        {columns[5].options.display === 'true' && <TableCell>{rowData[5]}</TableCell>}
        {columns[6].options.display === 'true' &&  <TableCell>
          <TableButton onClick={() => toggleProposalRequestView(rowData[0])}>
            <FontAwesomeIcon icon={faEye} />
          </TableButton>
          <Link to={`/dashboard/customers/proposal-requests/proposalrequestid/${rowData[0]}/edit`}>
            <TableButton>
              <FontAwesomeIcon icon={faPencilAlt} />
            </TableButton>
          </Link>
        </TableCell>}
      </TableRow>
    );
  }

  public getTableState() {
    return JSON.parse(localStorage.getItem('proposal-requests_list') || '{"filterList": []}');
  }

  public setTableState() {
    const { filter } = this.state;

    localStorage.setItem('proposal-requests_list', JSON.stringify(filter));
  }

  public apendFilterListToColumns(columns: MUIDataTableColumn[], filterList: string[][], all: boolean): any[] {
    const { location } = this.props;
    const { id } = parse(location.search, { ignoreQueryPrefix: true });

    filterList.forEach((filter, index) => {
      if (
        (
          (index !== 0 || !id)
        ) || all
      ) {
        (columns[index] || {}).options = {
          ...((columns[index] || {}).options || {}),
          filterList: filter,
        };
      }
    });

    return columns;
  }

  public render() {
    const { columns, filter } = this.state;
    const { proposalRequests, view } = this.props;

    return (
      <div className="dashboard-container">
        <Box>
          <Grid container>
            <Grid item sm={6}>
              <Typography variant="h6" component="h2">Clientes</Typography>
              <Breadcrumbs className="breadcrumbs" separator={<FontAwesomeIcon icon={faChevronRight} size="xs" />} aria-label="breadcrumb">
                <Typography color="textPrimary">Pedidos de Propostas</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item sm={6} style={{ textAlign: 'right' }}>
              <Link to="/dashboard/customers/proposal-requests/new">
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<AddIcon />}
                >Adicionar</Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
        
        <Box mt={2} pb={2}>
          <Card>
            <CardContent>
              <MUIDataTable
                title={''}
                data={proposalRequests.map((p) => ({
                  ...p,
                  machineType: (machineTypes.find((m) => m.id === p.machineType) || { label: 'Outro' }).label,
                }))}
                columns={columns}
                options={{
                  rowsPerPage: 100,
                  textLabels: tableTranslations,
                  selectableRows: 'none',
                  elevation: 0,
                  customRowRender: this.customRowRender,
                  onColumnViewChange: this.onColumnViewChange,
                  searchText: filter.searchText ?? undefined,
                  onTableChange: (action, tableState) => {
                    if (action === 'filterChange' || action === 'search') {
                      this.setState({
                        filter: {
                          filterList: tableState.filterList,
                          searchText: tableState.searchText,
                        },
                        columns: this.apendFilterListToColumns(columns, tableState.filterList, true),
                      }, this.setTableState);
                    }
                  }
                }}
              />
            </CardContent>
          </Card>
        </Box>
        {view ? <ViewModal /> : null}
      </div>
    );
  }
};

export default withRouter(List);